<template>
  <div class="header-bar" :class="{ 'transparent': transparent }">
    <div class="tops-heads">
      <div class="row items-center" v-if="showLogo">
        <img src="@/assets/img/logo2.png" style="width: 180px;" />
        <!-- <div class="logo-title">CITADEL AI</div> -->
      </div>
      <div class="row items-center" v-else>
        <img src="@/assets/img/logo.png" style="width: 30px;" />
        <div class="address q-ml-sm" style="padding-top: 4px;color: #000;">{{ address }}</div>
      </div>
      <div class="tops-row" v-if="showLand">
        <!-- <div class="level" v-if="usinfo && usinfo.ulevel > 0">
          <span>{{usinfo.ulevelName }}</span>
        </div> -->
        <van-popover v-model:show="showPopover" :actions="languages" placement="bottom-end" @select="onSelect">
          <div class="language-popover">
            <div class="language-item" v-for="(item, index) in languages" :key="index" @click="onSelect(item)">
              <img :src="item.img" class="language-icon" />
              <div>{{ item.text }}</div>
            </div>
          </div>
					<template #reference>
            <img src="@/assets/img/base/land.png" width="39" />
          </template>
				</van-popover>
        <van-popover v-model:show="showMenu" :actions="menu" placement="bottom-end" @select="menuChange">
					<template #reference>
						<img src="@/assets/img/base/menu.png" style="width: 35px;" class="q-ml-md" v-if="menulx == 1" />
						<img src="@/assets/img/base/menu2.png" style="width: 35px;" class="q-ml-md" v-if="menulx == 2" />
					</template>
				</van-popover>
      </div>
    </div>

    <div class="notice-box" v-if="showNotice">
      <van-notice-bar 
        left-icon="volume-o" 
        :text="notice" 
        background="transparent"
        :color="noticeColor"
        @click="tolink('Notice')"
      />
    </div>

    <div class="back-wrap" v-if="showBack">
      <img src="@/assets/img/base/back.png" width="48" @click="back" />
    </div>
  </div>
</template>

<script>
import { NoticeBar, Popover } from 'vant';
import { ref } from 'vue'

export default {
  name: '',
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    showNotice: {
      type: Boolean,
      default: true
    },
    showBack: {
      type: Boolean,
      default: false
    },
    autoBack: {
      type: Boolean,
      default: true
    },
    showLand: {
      type: Boolean,
      default: true
    },
    menulx: {
      type: [String, Number],
      default: 1
    },
    noticeColor: {
      type: String,
      default: '#000'
    },
    showLogo: {
      type: Boolean,
      default: false
    }
  },
  components: {
    [NoticeBar.name]: NoticeBar,
    [Popover.name]: Popover,
  },
  setup() {
    return {
      us: ref(null),
      address: ref(""),
      notice: ref(""),
      showPopover: ref(false),
      languages: ref([
        {text: "中文繁體", val: "zh", img: require('@/assets/img/base/zh.png')},
        {text: "English", val: "en", img: require('@/assets/img/base/en.png')},
        {text: "한국어", val: "kor", img: require('@/assets/img/base/kor.png')},
        {text: "Malay", val: "may", img: require('@/assets/img/base/may.png')},
        {text: "Tiếng Việt", val: "vie", img: require('@/assets/img/base/vie.png')},
        {text: "ภาษาไทย", val: "th", img: require('@/assets/img/base/th.png')},
        {text: "Indonesia", val: "id", img: require('@/assets/img/base/id.png')},
        {text: "日本語", val: "jp", img: require('@/assets/img/base/jp.png')},
      ]),
      usinfo: ref(null),
      showMenu: ref(false),
      menu: ref([])
    }
  },
  created() {
    this.setLanguage()
    this.getShowAddress()
    this.getGonggao()
    
  },
  methods: {
    setLanguage() {
      this.menu = [
        {text: this.$t('User.home'), val: "/"},
        // { text: this.$t('HomeFramework.menu2'), val: "RobotUserRecord" },
        { text: this.$t('HomeFramework.menu3'), val: "Bill" },
        { text: this.$t('HomeFramework.menu4'), val: "Bonus" },
        { text: this.$t('HomeFramework.menu5'), val: "Wallet" },
        { text: this.$t('HomeFramework.menu7'), val: "AiData" },
        { text: this.$t('AiData.ziliaoku'), val: "Database" },
        { text: "节点理财", val: "NodeOrder" },
        { text: "智能理财", val: "NewRobotOrder" },
        { text: "质押理财", val: "PledgeOrder" },
        { text: "哈希竞猜", val: "Jingcai" },
      ]
    },
    back() {
      if(this.autoBack) {
        this.$router.back()
      } else {
       this.$emit("onBack") 
      }
    },
    tolink(path) {
      this.$router.push(path)
    },
    menuChange(e) {
      this.$router.push(e.val)
    },
    onSelect(e) {
      this.$utils.setloc("language", e.val)
      this.$i18n.locale = e.val;
      this.$emit("setLanguage")
      // this.getUser()
      this.setLanguage()
      this.showPopover = false
    },
    getGonggao() {
      let _this = this
      _this.$request.post(
        "Api/News/Gonggao",
        {},
        (res) => {
          if(res.data.code == 0) {
            _this.$q.dialog({
							message: res.data.msg,
						});
          }
          let data = res.data.data
          _this.notice = data
        }
      )
    },
    getShowAddress() {
      this.us = JSON.parse(this.$utils.getloc("us"))
      if(this.us) {
        this.address = this.us.address.replace(
          this.us.address.substring(4, this.us.address.length - 4),
          "***"
        )
        // this.getUser()
      }
    },
    getUser() {
      let _this = this
      _this.$request.post(
        "api/Users/Get",
        {
          token: _this.$utils.getloc("token"),
					userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
						_this.$q.dialog({
							message: res.data.msg,
						});
					}
          let data = res.data.data.us
          _this.usinfo = data
          // console.log(data)
        }
      )
    }
  }
}
</script>

<style scoped>
.header-bar {
  box-sizing: border-box;
  padding: 30px 12px 16px;
  min-height: 200px;
  background: url(~@/assets/img/base/header-bar.png) no-repeat;
  background-size: 100% auto;
  background-color: #f4f4f9;
}

.transparent {
  background: transparent;
}

.tops-heads {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tops-row {
  display: flex;
  align-items: center;
}

.address {
  font-size: 16px;
  margin-right: 10px;
}

.notice-box {
  margin-top: 10px;
}

.back-wrap {
  margin-top: 16px;
}

.level {
  margin-right: 10px;
  padding: 2px 20px;
  border-radius: 99rem;
  background-color: #000;
}

.logo-title {
  margin-left: 8px;
  font-weight: bold;
  color: #fff;
  font-size: 21px;
}

.language-popover {
  padding: 0 16px;
}

.language-item {
  height: 44px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebedf0;
}

.language-item:last-child {
  border: none;
}

.language-icon {
  margin-right: 6px;
  width: 25px;
}
</style>
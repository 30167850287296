
module.exports = {
  Base: {
    copy: "Copy success",
    back: "Back",
    empty: "No data yet",
    empty2: "No records available",
    tishi: "Tip",
    queding: "Confirm",
    quxiao: "Cancel",
    tijiao: "Submitting...",
  },
  CheckRegister: {
    tuijianren: "Referrer",
    placeholder: "Please enter the referral address",
    register: "Registered",
    addressErr: "Get address error:",
    qingshuruyonghuming: "Enter one user name",
    qingshuruyongmima: "Please input a password",
    dengluzhong: "Logging in"
  },
  Home: {
    gonggao: "Notice",
    rules: "Rule description",
    purchaseRecord: "Purchase Record",
    select: "Select Level",
    amount: "Purchase amount",
    submit: "Purchase node",
    chongzhi: "Top up now",
    referrers: "My referrers",
    link: "Invitation link",
    list: "Subscription list",
    address: "Address",
    listAmount: "Subscription amount",
    state: "State",
    completed: "Completed",
    message1: "Replicating Success",
    message2: "Are you sure you want to purchase?",
    message3: "In purchasing...",
    down: "Rush From The Node",
    day: "D",
    hours: "H",
    seconds: "S",
    jieshu: "The Node Rush Has Ended",
    downTitle1: "Edison AI Node Partner",
    downTitle2: "Global Recruitment",
    zhitui: "Direct push list",
    qianggouzhong: "Second phase node in rush purchase",
    minutes: "M",
    jiedian: "Node hash power",
    jifaqianneng: "Ignite unlimited potential, create infinite possibilities",
    jinridapp: "Enter DAPP",
    guanyu: "About us",
    lianjiequkuai: "Bridging the gap between blockchain technology and real-world business needs",
    zhanxianshequ: "Demonstrating the power of community building and consensus in practice",
    sectionmsg1: "CITADEL AI is a global community alliance composed of blockchain leaders, innovators, developers, and international enterprises. The alliance will serve as a catalyst for the growth and prosperity of the CITADEL AI blockchain, developing new business opportunities, driving industry applications, and promoting learning and collaboration.",
    sectionmsg2: "We gather a global community with a strong belief in CITADEL AI, aiming to provide guidance, support, and management for enterprises in blockchain technology and emerging technologies.",
    zhize: "Alliance Responsibilities",
    zhizemsg: "CITADEL AI is the core force of the CITADEL AI ecosystem, upholding the spirit of decentralization and promoting the vigorous development of CITADEL AI public chain technology and ecosystem through various means.",
    shequ: "Community Plan",
    shequmsg1: "Building CITADEL AI's future together",
    shequmsg2: "CITADEL AI continues to play a key role in the construction and operation of the CITADEL AI community. As members of CITADEL AI, users will integrate into a vibrant community, participate in decision-making, organize activities, and shape the future blueprint of CITADEL AI together with other members.",
    shequmsg3: "Towards the decentralized Web3 era",
    shequmsg4: "The decentralized community participation model we adhere to perfectly aligns with the vision of Web3, enhancing the transparency of the CITADEL AI ecosystem and ensuring fairness and universality in every decision.",
    qiye: "Enterprise Plan",
    qiyemsg1: "Providing innovation-driven blockchain solutions for enterprises",
    qiyemsg2: "CITADEL AI deeply cooperates with global enterprises, continuously exploring the unlimited possibilities of CITADEL AI blockchain technology. We are committed to providing targeted blockchain solutions for enterprises to help them maintain competitiveness in the digital economy era.",
    qiyemsg3: "Maximizing the potential of the CITADEL AI mainnet",
    qiyemsg4: "We will also provide comprehensive support for enterprises, helping them understand and leverage the technical advantages of the CITADEL AI mainnet as well as its unique strengths in data security and reliability, ensuring the best experience for enterprises in the CITADEL AI ecosystem.",
    qukuaigonglian: "Blockchain Public Chain",
    xiayidai: "The next-generation Web3 decentralized underlying public chain",
    xinjingji: "Blockchain solutions for the new economic era",
    xinjingjimsg: "CITADEL AI, a blockchain network tailored for the new economy, provides developers with independent deployment and operation space for distributed applications through its unique main chain + multi-sidechain architecture, achieving resource optimization and efficient utilization. With efficient parallel computing and consensus mechanisms, CITADEL AI provides strong support for high-frequency transactions. Meanwhile, its cross-chain technology ensures smooth communication and value transfer between chains.",
    kaifgongju: "Powerful governance and development tools",
    kaifgongjumsg: "Different business scenarios require different governance models, and CITADEL AI deeply understands this, thus providing various governance models such as parliamentary governance, popular governance, and association governance. To ensure the continuous and healthy development of the system, CITADEL AI also designs sophisticated incentive models. In addition, CITADEL AI provides advanced IDE tools to help developers develop, debug, and deploy applications more easily.",
    zhongxinhua: "Contributing to decentralized governance",
    zhongxinhuazeren: "As block producers of the CITADEL AI mainnet, CITADEL AI shoulders critical responsibilities.",
    zhongxinhuali1: "Ensuring network security",
    zhongxinhuali2: "Optimizing consensus decision-making",
    zhongxinhuali3: "Maintaining optimal operation of sidechain nodes",
    zhongxinhuali4: "Sustaining network stability",
    pianzhng: "Embarking on a new chapter",
    pianzhnglabel1: "Not just a blockchain network",
    pianzhngmsg1: "It also strives to embrace the concept of decentralized autonomous organizations (DAOs). To this end, CITADEL AI has established its own DAO and launched a global node recruitment program based on it.",
    pianzhnglabel2: "CITADEL AI is committed to recruiting 20 senior nodes globally",
    pianzhngmsg2: "Each representative is an elite in different fields, either international institutions or well-known figures in a certain field. These 20 representatives will participate and make decisions on important issues regarding the CITADEL AI ecosystem, promoting CITADEL AI to a higher level of development.",
    pianzhnglabel3: "Decentralized autonomous organization is an important application of blockchain technology",
    pianzhngmsg3: "It abandons the traditional centralized management approach, making the decision-making process more democratic and transparent. In the architecture of DAO, each member can vote based on their contribution or stake in the system, thus jointly deciding the future direction of the organization.",
    pianzhnglabel4: "The establishment of the CITADEL AI Foundation is to allow more people to participate in",
    pianzhngmsg4: "the development of CITADEL AI and jointly create the glorious history of CITADEL AI",
    mubiao: "Main Goals",
    mubiaolabel1: "Ecosystem curation",
    mubiaomsg1: "Select, support, and promote high-potential projects in the CITADEL AI ecosystem.",
    mubiaolabel2: "Technical validation",
    mubiaomsg2: "Responsible for ensuring network security through block production and transaction verification.",
    mubiaolabel3: "Cross-chain coordination",
    mubiaomsg3: "Establish partnerships with other blockchain entities to ensure CITADEL AI maintains interoperability and updates according to the latest industry standards.",
    mubiaolabel4: "Community development",
    mubiaomsg4: "Lead community development, build consensus, create a positive and interactive space, and jointly promote the prosperity of the CITADEL AI ecosystem.",
    mubiaolabel5: "DAO operation and management",
    mubiaomsg5: "Supervise the submission, discussion, and voting of community proposals to ensure that these proposals align with the mission and values of CITADEL AI.",
    mubiaolabel6: "Capital fundraising",
    mubiaomsg6: "Oversee fundraising efforts to ensure the growth and financial stability of the CITADEL AI ecosystem.",
    time1: "Q4 2023",
    time2: "Q1 2024",
    time3: "Q2 2024",
    time4: "Q3 2024",
    time5: "Q4 2024",
    time6: "2025",
    time7: "2026",
    timemsg1: 'Actively recruit, aiming to attract 100,000 users worldwide, covering 20 countries',
    timemsg2: 'Drive research and implementation of Web3, aligning the CITADEL AI ecosystem with the latest developments in Web3',
    timemsg3: 'Assist the Aclevate funding program, promote game development teams admitted by CITADEL AI, increase their fan base and market popularity, and give the CITADEL AI ecosystem greater influence in the gaming finance field',
    timemsg4: 'Attract more businesses and users to join CITADEL AI through online and offline conferences, trade shows, expos, and other community events',
    timemsg5: 'Strengthen relationships with global partners to jointly promote the development of the CITADEL AI ecosystem',
    timemsg6: 'Build the GameFi Alliance, making CITADEL AI a leader in Play-to-Earn games and other GameFi innovations',
    timemsg7: 'Successfully attract 500,000 users worldwide, covering at least 50 countries; assist CITADEL AI in adding at least 30 new projects from different fields within its ecosystem, and continuously expand and enhance the functionality and diversity of the CITADEL AI ecosystem',
    timemsg8: 'Assist in establishing the CITADEL AI Incubator, supporting and promoting the development of innovative projects on the CITADEL AI public chain',
    timemsg9: 'Advance the SocialFi ecosystem, making CITADEL AI a pioneer in social finance and related innovations',
    timemsg10: 'Establish the CITADEL AI Academy, providing educational resources and training to enhance the blockchain knowledge and skills of community members',
    timemsg11: 'Initiate multiple community bounty programs to incentivize more people to participate in the construction of the CITADEL AI ecosystem',
    timemsg12: 'Successfully recruit 1 million users worldwide, covering 100 countries',
    timemsg13: 'Build the CITADEL AI ecosystem into the worlds strongest and most diverse blockchain ecosystem',
    genzongtitle: 'Defi Liquidity Mining Tracking Robot',
    genzonglabel: 'The ultimate tool for investment monitoring and maximizing returns.',
    genzongmsg1: 'Our robot is carefully designed to track and analyze the returns generated by various investment tools and strategies in the decentralized finance (DeFi) ecosystem.',
    genzongmsg2: 'Investors can gain unique insights into investment performance and profitability from our state-of-the-art robot. Easily access the latest yield metrics, assess strategy effectiveness, make informed decisions, and optimize investment returns.',
    genzongmsg3: 'Whether it staking, mining, or participating in liquidity pools, our DeFi liquidity mining tracking robot closely monitors your investment situation, providing real-time data and comprehensive analysis.',
    genzongmsg4: 'Do not miss out on the opportunity to maximize returns.',
    trade: 'DeFi Trading Robot',
    tradelabel: 'Introducing the world most advanced automated trading robot.',
    chuangjian: 'Create',
    jiaoyisuanfa: 'Trading Algorithm',
    shiyong: 'Usage',
    zhibiao: 'Our customized technical indicators',
    jiedu: 'Interpretation',
    xinhao: 'Signals Made Easy',
    trademsg1: 'We have embedded our self-developed trading robot into feature-rich terminals, operating across several major decentralized exchanges, providing clients with efficient and precise execution.',
    trademsg2: 'We are integrating the system with the ERC chain in the next upgrade to increase trading volume, while driving profit growth, and providing clients with a stable network to help them seize opportunities in the dynamic cryptocurrency market.',
    kefu: "Telegram customer service"
  },
  HomeFramework: {
    bannerTitle: "Opening up an extraordinary world of quantification",
    bannerMsg1: "Create a new generation of intelligent quantitative wealth platform in the field of AI.",
    bannerMsg2: "Edison AI, a mysterious force from the depths of AI.",
    bannerMsg3: "Fairness、Freedom、Faith、Future",
    aboutTitle1: "Multi-level",
    aboutTitle2: "Multi-angle",
    aboutTitle3: "Multi-data",
    aboutMsg1: "Including models at three levels: asset allocation, industry selection, and selection of specific assets",
    aboutMsg2: "The core ideas of quantitative investment include macro cycle, market structure, valuation, growth, earnings quality, analyst profit forecast, market sentiment and other perspectives",
    aboutMsg3: "That is the processing of massive amounts of data",
    robotTitle: "Edison AI Quantitative Robot",
    robotMsg: "It is a new generation of intelligent financial assistant, combined with advanced AI big model data analysis, to provide accurate market trend analysis to help you make informed investment decisions. It will tailor a personalized investment strategy for you according to your needs and risk appetite, bringing higher investment returns.",
    platformTitle: "Platform Vision",
    platformMsg: "Using AI and blockchain technology, create an inclusive financial platform to establish a safe, transparent, fair and efficient investment environment for all.",
    footerMsg: "Edison AI Wealth Center is a wealth management platform based on AI artificial intelligence, big data and blockchain technology, focusing on new, open source and decentralized AI innovation fields, with EDISON AI F.D.T LIMITED UK Foundation as the cornerstone, AI large model database, AI supercomputing and massive data TPS as the support, forming a perfect set of intelligent running code, so that AI quantification is truly fair, safe, efficient and fast.",
    menu2: "My Commission",
    menu3: "My Bill",
    menu4: "My Earnings",
    menu5: "My Assets",
    menu6: "My Service",
    menu7: "Quantitative strategy",
  },
  HomeService: {
    title: "Services we provide",
    msg: "The Edison system has a powerful AI large model database, and Two Sigma gives full technical support and data support, backed by massive data and huge computing power. It has more than 10,000 data sources, 95,000 CPUs of more than 1000TB, 22 million GB of real-time increased data, a computing speed of 1 x 10^14 per second, and a database capacity of up to 1695TB, which has no barriers to running the most complex investment ideas.",
    serviceTitle1: "Quantitative Services",
    serviceTitle2: "Solve Pain Points",
    serviceTitle3: "Expert Advisors",
    serviceTitle4: "Intelligent Embodiment",
    serviceTitle5: "Trading Edge",
    serviceTitle6: "Trading Technology",
    list1Msg1: "Enterprise-level AI underlying platform, leading quantitative trading algorithm, automatic machine learning, hyperparameter optimization, and rapid improvement of AI quantitative processing efficiency",
    list1Msg2: "PB-level quantitative proprietary standard financial data + new investment big data, support various types of data customization, to meet different investment needs",
    list1Msg3: "2000+ basic factor library, AI supports mining more derivative factors, one step ahead, and more market opportunities",
    list1Msg4: "The expression engine creates a diverse factor library that supports comprehensive analysis of factors and faster discovery of effective factors",
    list1Msg5: "Modular visual development environment, seamless integration with Python code version, improve strategy development efficiency",
    list1Msg6: "Hyperparameter search, combination optimizer, rolling training, attribution analysis, etc., custom equipment for high-end players",
    list2Msg1: "Since 2021, EDISON AI has only reached in-depth cooperation with mainstream exchanges Binance, Oeyi and Huobi to avoid the situation of exchanges running away to the greatest extent",
    list2Msg2: "By default, it is recommended that customers only trade mainstream old currencies, avoid altcoins and popular coins, and avoid similar LUNA thunderstorm events to the greatest extent",
    list2Msg3: "It is not affected by the news of any media exchange joint cutting leek, and the strategy is strictly and completely implemented to avoid the negative emotional impact of users.",
    list2Msg4: "The robot is deployed in the cloud service, does not occupy your local computer and network, and runs the strategy automatically 24 hours a day to solve the problem of customer energy.",
    list2Msg5: "24-hour automatic intelligent millisecond calculation and transaction speed, grasp the sudden market, and solve the problem that the artificial speed of the large market cannot keep up.",
    list2Msg6: "Built-in EDISON's three-year continuously optimized trading mathematical mode, through statistical historical big data combined with calculation formula to calculate intelligent mode margin call",
    list3Msg1: "By moving to track minute K-line data, the take profit point and margin call point are intelligently modified to maximize profits and reduce buying costs",
    list3Msg2: "By setting the minute line to rise and fall more than a certain value, that is, adjust the interval point or stop, solve the problem of waterfall and pin insertion of manual pending orders",
    list3Msg3: "Customizable strategies, including martingale, flat push series, Fibonacci, etc., to avoid the risk of stud gambling for customers to the greatest extent",
    list3Msg4: "The intelligent mode suitable for one-click start of Xiaobai, and the professional mode + indicator mode assisted by quantitative masters, suitable for all people",
    list3Msg5: "Bear markets can open short, bull markets can open more, shock markets can also be long and short double-open, unilateral markets can be multi-short linkage, for their own diverse situations",
    list3Msg6: "Accurately calculate the transaction amount of each order for different series of position transactions, avoiding the cumbersome problem of manual calculation to the greatest extent",
    list4Msg1: "Intelligent mathematical model, built-in EDISON built-in calculation model, according to the current price and historical data, has a period of volatility, intelligent calculation, real-time adjustment of the next margin call",
    list4Msg2: "Intelligent real-time tracking K-line, applied to the tracking take profit tracking position opening function, real-time adjustment of take profit and margin call points, amplifying profits and reducing costs",
    list4Msg3: "Intelligent monitoring of trading depth, monitoring the depth of the exchange with the maximum limit value of the exchange, and pursuing the best price to buy and sell",
    list4Msg4: "Intelligent statistics of historical data, intelligent statistics of transaction currency historical data and fluctuation range, support the stable operation of intelligent mode",
    list4Msg5: "Intelligently adjust the transaction speed, intelligently adjust the transaction speed according to market fluctuations, quickly grasp the best price while optimizing the stable operation of the server",
    list5Msg1: "Transaction speed",
    list5Msg2: "Milliseconds, fast, and stable",
    list5Msg3: "Energy time",
    list5Msg4: "Run trades 24 hours a day without sleep",
    list5Msg5: "Intelligent data",
    list5Msg6: "Position calculation and transaction data calculation are fast and accurate",
    list5Msg7: "Trading model",
    list5Msg8: "A complete and stable trading model",
    list5Msg9: "Policy enforcement",
    list5Msg10: "The overall strategy is strictly enforced",
    list5Msg11: "Real-time tracking",
    list5Msg12: "Real-time marking, intelligent movement tracking K-line",
    list5Msg13: "Emotional effects",
    list5Msg14: "Unaffected by the emotions of the host",
    list5Msg15: "Media impact",
    list5Msg16: "Don't be misled by fake news",
    list5Msg17: "Profitability",
    list5Msg18: "Short-term windfall profits can be stable for a long time",
    list6Msg1: "Trading technology",
    list6Msg2: "Independent research and development, strong and stable, high industry recognition",
    list6Msg3: "Run history",
    list6Msg4: "EDISON has been focusing on quantitative research for 8 years and has been published for 3 years",
    list6Msg5: "Charges",
    list6Msg6: "The global market is unified, transparent and unhidden",
    list6Msg7: "Trading model",
    list6Msg8: "Independent research and development of stable trading models, two major models",
    list6Msg9: "Commission situation",
    list6Msg10: "not",
    list6Msg11: "Platform selection",
    list6Msg12: "Only Binance Oyi Huobi is supported, and customers can choose by themselves",
    list6Msg13: "Security situation",
    list6Msg14: "EDISON eight years of stable quantitative guarantee",
    list6Msg15: "Update optimizations",
    list6Msg16: "Four years to keep up with the market optimization update iteration",
    list6Msg17: "Total number of users",
    list6Msg18: "Stable customer group, market share of 70%+",
  },
  HomeStepper: {
    title: "EDISON",
    title2: "Development Plan",
    step1Msg1: "the Al large model database was built and trained;",
    step1Msg2: "an encrypted operation team was set up to lay out the encryption track;",
    step2Msg1: "the implementation of artificial intelligence AI quantitative investment began;",
    step2Msg2: "financial assets managed by artificial intelligence AI showed explosive growth;",
    step2Msg3: "it began to invest in high-quality crypto trading platforms and DEFI projects;",
    step3Msg1: "Two Sigma invests in the Edison Al Foundation;",
    step3Msg2: "the Foundation's Edison AI Wealth Hub platform was launched;",
    step3Msg3: "the Edison AI aggregation robot was launched;",
    step3Msg4: "the Edison AI DEFI section was launched;",
    step4Msg1: "the EdisonAI public chain wallet was launched;",
    step4Msg2: "Edison AI encrypted social was launched;",
    step5Msg1: "the EdisonAl public chain ecological application was launched;",
  },
  Chongzhi: {
    title: "Recharge",
    bill: "My bill",
    chakan: "View",
    placeholder: "Please enter the amount of top-up",
    balance: "Wallet balance",
    queding: "Confirm",
    guangbo: "The transaction is broadcast successfully",
    message1: "Please enter the amount of top-up",
    message2: "Insufficient wallet balance",
    message3: "Trading...",
    message4: "Please enter the correct quantity",
    xiugai: "Modify",
    bangding: "You have not bound the TRC20 address, bind now",
    message5: "Please enter TRC20 address",
    message6: "Modifying",
    message7: "Please enter the correct quantity",
    message8: "The TRON wallet address you are currently using does not match the address you are bound to. Please modify it before making the transfer",
    message9: "*Please use the bound wallet address to transfer the recharge quantity to the receiving address",
    message10: "After the transfer is successful, please wait for 2 minutes for the block to be confirmed and automatically credited!",
    shoubi: "Platform receiving address",
    bangdingdizhi: "Binding address",
    copy: "Copy address",
    shouquan: "Authorized",
    shouquanjine: "Authorized amount",
    shouquantip: "If the authorization amount is insufficient, please authorize it first",
    yishouquan: "Authorized",
    shouquanzhong: "Authorizing",
    chongzhizhong: "Recharging",
    shouquanchenggogn: "Authorization successful"
  },
  Bill: {
    title: "My bill",
    date: "Date",
    leixing: "Type",
    coin: "Currency",
    num: "Quantity",
    finished: "No more",
    all: "All",
    shouyi: "Earnings",
    chongzhi: "Recharge",
    tixian: "Withdrawals",
    zhuanzhang: "Transfer",
    zhuanhuan: "Conversion",
    zengjian: "Changes",
    xiaofei: "Purchase"
  },
  NodeRecord: {
    title: "Purchase Record",
    date: "Date",
    node: "Node",
    amount: "Amount",
    finished: "No more"
  },
  Bonus: {
    title: "Earnings",
    date: "Date",
    type: "Type",
    jine: "Amount",
    finished: "No more",
    jinri: "Today's earnings",
    leiji: "Cumulative earnings"
  },
  Team: {
    title: "Direct push list",
    address: "Address",
    jine: "Money",
    jibie: "Level",
    wu: "Nothing",
    yeji: "Team node performance",
    jiedianyeji: "Node results",
    aiyeji: "AI results"
  },
  User: {
    home: "Home",
    chongzhi: "Voucher Center",
    bonus: "My Benefits",
    ai: "AI Data",
    mining: "Mining",
    notice: "Notice"
  },
  Notice: {
    title: "Notice",
    details: "Notice Detail"
  },
  TabBar: {
    home: "Market",
    ai: "AI Witness",
    mining: "Wealth",
    team: "Team",
    user: "My"
  },
  Market: {
    dagnqianjiage: "Current price",
    chushi: "Initial price",
    mairu: "Buy",
    maichu: "Sell",
    liudongxing: "liquidity",
    xinxi: 'Information',
    kongtou: "CCAI Airdrop Rewards",
    dangqian: "Total",
    daibixiaohui: 'CCAI Token Burn',
    xiaohuizongliang: 'Total Amount of CCAI Burned',
    chubirenshu: 'Number of CCAI Holders',
    faxingzongliang: 'Total Amount of CCAI Issued',
    jiedian: 'Node',
    jine: 'Amount',
    suanli: 'Hashrate',
    zuori: "Yesterday",
    jinri: "Today"
  },
  AiData: {
    zongjiazhi: "Lock in total value (USD)",
    celue: "Algorithmic mesh strategy",
    totalMsg: "Citadel is one of the most influential quantitative hedge funds in the world, utilizing complex artificial intelligence and machine learning models to manage assets and optimize market transactions. Citadel Securities' electronic trading department extensively utilizes AI technology to improve trading execution efficiency. CITADEL AI robots will develop and improve strategies in real-time based on deep learning and monitoring of hundreds of transactions on big data every day, and form instructions, providing sufficient technical and data support, backed by massive data and computing power. It has over 10000 data sources and 95000 CPUs with over 1000TB.",
    botTitle: "Number of arbitrage orders",
    jiangli: "Reward",
    rilirun: "Daily profit",
    yuelirun: "Monthly profit",
    jiage: "Price",
    zjc: "Capital Pool",
    ailh: "AI Quantification",
    aijy: "AI Trading",
    poolStats: "Pool Stats",
    arbitrageStats: "Arbitrage Stats",
    zongyue: "Total balance",
    baozhengjin: "Deposit (USDT)",
    qianbaoyue: "Wallet balance (USDT)",
    yunxingyingkui: "Profit and Loss (USDT)",
    zongyingkui: "Profit (USDT)",
    name: "Currency",
    price: "Price",
    aiweituo: "AI Delegation",
    aimingxi: "AI Transaction Details",
    dingyue: "Subscribe",
    jilu: "Record",
    ziliaoku: "News",
    chengjiaoliang: "Trading Volume",
    jiaoyidui: "Trading Pair",
    chengjiaotu: "Volume Chart",
    paixu: "Sort",
    bizhong: "Currency",
    price24: "Price (24h)",
    wending: "Stable Connection",
    zhichi: "Online Support"
  },
  Mining: {
    mingcheng: "Token name",
    fuhao: "Token symbol",
    gongyingliang: "Total supply",
    qishi: "Starting price",
    jilu: "Delegate records",
    chakan: "View",
    aibot: "AI robots",
    rishouyi: "Daily earnings",
    weituojine: "The amount of the commission",
    weituo: "Entrust",
    aggregation: "Aggregation robots",
    placeholder1: "Please enter the amount",
    queren: "Confirm",
    jijiangkaifang: "Opening soon",
    echartsName1: "Community distribution",
    echartsName2: "Node assignment",
    echartsName3: "Foundation distribution",
    echartsName4: "Hashrate mining pools",
    placeholder2: "Please enter",
    loading: "Commissioned...",
    jiqiren1: "S1 Robot",
    jiqiren2: "S2 Robot",
    jiqiren3: "S3 Robot",
  },
  TeamCount: {
    tuijian: "Recommend",
    tuijianren: "My referrers",
    lianjie: "Invitation link",
    tuandui: "Team",
    teamCount: "Number of team registrations",
    liebiao: "Direct Push List",
    usYejiTotal: "Individual AI Achievements",
    teamYejiTotal: "Team AI performance",
    rtaShouyi: "AI benefits",
    rtbShouyi: "Aggregate benefits",
    ktDay: "Daily airdrop volume",
    ktTotal: "IDO earnings",
    jiedianyeji: "Team node performance",
    usjiedianyeji: "Individual node performance",
    usxh: "Aggregation destruction",
    teamxh: "Team aggregate destruction",
    jiedianshouyi: "Node benefits",
    youxiao: "The number of valid people in the team"
  },
  Wallet: {
    total: "Total assets",
    bill: "My bill",
    bonus: "My earnings",
    maichu: "Sell",
    chongzhi: "Recharge",
    tixian: "Withdrawals",
    mairu: "Buy",
    shandui: "Flash",
    keyong: "Available",
    dongjie: "Freeze",
    zichan: "Asset",
    zhuanzhang: 'Transfer'
  },
  Convert: {
    mairu: "Buy",
    maichu: "Sell",
    shandui: "Flash",
    yue: "Balance",
    placeholder1: "Please enter quantity",
    edu: "Subscription Amount",
    sxf: "Premium",
    ranliao: "Equivalent fuel CCAI",
    querenmairu: "Confirm the buy",
    querenmaichu: "Confirm the sale",
    message1: "Are you sure you want to buy?",
    message2: "Are you sure you want to sell?",
    loading1: "Loading...",
    message3: "Are you sure you want to convert?",
    loading2: "In the flash..."
  },
  Tixian: {
    title: "Withdrawals",
    bill: "My bill",
    chakan: "View",
    select: "Please select a currency",
    numLabel: "Please enter the withdrawal quantity",
    placeholder: "Number of withdrawals",
    addressLabel: "Please enter the payout address",
    placeholder2: "Withdrawal address",
    yue: "Wallet balance",
    sxf: "Premium",
    queding: "Confirm",
    message1: "Please select a currency",
    message2: "Please enter the withdrawal quantity",
    message3: "Are you sure you want to withdraw?",
    loading: "Withdrawing..."
  },
  RobotUserRecord: {
    title: "Delegate records",
    date: "Time",
    robot: "Robot",
    jine: "Amount",
    state: "State",
    finished: "No more",
    shouyi: "In earnings",
    shuhui: "Redeem",
    yijieshu: "Ended",
    yishuhui: "Redeemed ",
    empty: "No records",
    message: "Are you sure you want to redeem it?",
    loading: "Redeeming...",
    ranshao: "Fuel rises",
    suanli: "Hash power"
  },
  NodePower: {
    title: "Node hash power",
    date: "Reward time",
    jine: "Reward amount",
    finished: "No more",
  },
  Record: {
    cz: 'Recharge records',
    tx: "Withdrawal history",
    shijian: "Time",
    jine: "Amount",
    hash: "Hash",
    zz: "Transfer records",
    suserid: "Payee"
  },
  Zhuanzhang: {
    title: "Transfer",
    bill: "Transfer records",
    chakan: "view",
    select: "Please select a currency",
    numLabel: "Please enter the transfer quantity",
    placeholder: "Transfer quantity",
    addressLabel: "Please enter the recipient's address",
    placeholder2: "Beneficiary Address",
    yue: "Wallet balance",
    sxf: "Handling fees",
    queding: "Confirm",
    message1: "Please select a currency",
    message2: "Please enter the transfer quantity",
    message3: "Are you sure you want to transfer money?",
    loading: "Transferring"
  },
  AiTradeDetail: {
    title: "Transaction Details",
    pingcang: "Closing position",
    jiage: "Price (USDT)",
    num: "Quantity (USDT)",
    shouyi: "Income",
    yongxu: "Sustainable"
  },
  Service: {
    shuruwenti: "Please enter your question",
    bunengweikong: "The message content cannot be empty",
    shangchuanshibai: "Failed to upload image",
    message: "Please upload images within 5M",
    message2: "Please upload images in JPG format"
  },
  Subscribe: {
    title: "Subscription",
    zhineng: "AI Trading",
    zongdingyue: "Total Subscriptions",
    zonghuoyue: "Total Active",
    zhungtai: "Status",
    shuchu: "Max Output",
    zhanghu: "Account",
    beidongshouru: "Passive Income",
    zhudongshouru: "Active Income",
    zongshouru: "Total Income",
    mima: "Password"
  },
  aiLogin: {
    title: "Welcome back!",
    label: "Please enter your account to log in",
    account: "Account",
    password: "Password",
    check: "Remember me",
    submit: "Log in",
    placeholder1: "Enter your account",
    placeholder2: "Enter your password"
  },
  Trading: {
    tubiao: "Chart",
    huobi: "Currency",
    jiage: "Price",
    buy: "Buy"
  },
  NavBar: {
    logout: "Logout",
    shouye: "Home",
    positions: "Open Positions",
    closedPositions: "Closed Positions",
    aiConfig: "AI configuration"
  },
  AiConfig: {
    tab1: "Overview",
    tab2: "Data",
    tab3: "Investment Composition",
    tab4: "Trading History",
    tab5: "Deposit Records",
    biaoxian: "Performance",
    guanyu: "About",
    huichou: "Total Return",
    dingyuejine: "Minimum Subscription Amount",
    shuju: "Data",
    lirun: "Best Profit",
    lirunbaifenbi: "Profit Trading Percentage",
    jiaoyishu: "Total Trades",
    dingdan: "Orders",
    riqi: "Date",
    leixing: "Trade Type",
    jine: "Amount",
    huobi: "Currency",
    maimai: "Buy/Sell",
    kaipiaojia: "Invoice Price",
    kaidanriqi: "Order Date",
    dangqianjiage: "Current Price",
    chengjiaoliang: "Volume",
    yingkui: "Profit/Loss",
    shoupanjia: "Closing Price",
    guandanriqi: "Closing Date"
  },
  Pledge: {
    zhiya: "Pledge",
    zhiyajilu: "Pledge Record",
    xuanzezhouqi: "Select Cycle",
    qingxuanzezhouqi: "Please select a cycle",
    zhiyashuliang: "Pledge Amount",
    qingshurubeishu: "Please enter 100 or multiples of 100",
    zhifufangshi: "Payment Method",
    querenzhiya: "Confirm Pledge",
    qingshurushuliang: "Please enter amount",
    zhiyazhong: "Pledging...",
    shijian: "Time",
    zhouqi: "Cycle",
    jine: "Amount",
    zhuangtai: "Status",
    chezi: "Withdraw",
    shouyizhong: "Earning",
    yichezi: "Withdrawn",
    querenchezi: "Confirm withdrawal?",
    chezizhong: "Withdrawing..."
  }
}

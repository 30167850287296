module.exports = {
  Base: {
    copy: 'คัดลอกสำเร็จ',
    back: 'กลับ',
    empty: 'ยังไม่มีข้อมูล',
    empty2: 'ไม่มีการบันทึกชั่วคราว',
    tishi: 'เคล็ดลับ',
    queding: 'ระบุ',
    quxiao: 'การยกเลิก',
    tijiao: 'กำลังส่ง...'
  },
  CheckRegister: {
    tuijianren: 'แนะนำโดย',
    placeholder: 'กรุณากรอกที่อยู่ของผู้แนะนำ',
    register: 'ลงทะเบียน',
    addressErr: 'การลงทะเบียนเพื่อรับที่อยู่ผิดพลาด:',
    qingshuruyonghuming: "กรุณากรอกชื่อผู้ใช้",
    qingshuruyongmima: "กรุณากรอกรหัสผ่าน",
    dengluzhong: "เข้าสู่ระบบ"
  },
  Home: {
    gonggao: "ประกาศ",
    rules: 'คำอธิบายกฎ',
    purchaseRecord: 'บันทึกการซื้อ',
    select: 'เลือกระดับ',
    amount: 'ยอดซื้อ',
    submit: 'โหนดซื้อ',
    chongzhi: 'เติมเงินตอนนี้',
    referrers: 'อ้างอิงของฉัน',
    link: 'เชิญเข้าร่วม',
    list: 'รายการสมัครสมาชิก',
    address: 'ที่อยู่',
    listAmount: 'จำนวนสมาชิก',
    state: 'สถานะ',
    completed: 'เสร็จสมบูรณ์',
    message1: 'คัดลอกสำเร็จ',
    message2: 'คุณแน่ใจนะว่าอยากจะซื้อ',
    message3: 'กำลังซื้อ ...',
    down: 'โหนดระยะทาง Grab ซื้อ',
    day: 'วัน',
    hours: 'เวลา',
    seconds: "วินาที",
    jieshu: 'Node Grab สิ้นสุดลงแล้ว',
    downTitle1: 'เอดิสัน AI Node Partner',
    downTitle2: 'ในการสรรหาบุคลากรทั่วโลก',
    zhitui: 'รายการผลักดันตรง',
    qianggouzhong: 'ใน Grab โหนดระยะที่สอง',
    minutes: 'คะแนน',
    jiedian: 'แรงนับโหนด',
    jifaqianneng: 'เร้าใจศักย์ไร้ขอบเขต สร้างความเป็นไปได้ที่ไม่มีที่สิ้นสุด',
    jinridapp: 'เข้าสู่ DAPP',
    guanyu: 'เกี่ยวกับเรา',
    lianjiequkuai: 'สร้างการเชื่อมต่อระหว่างเทคโนโลยีบล็อกเชนและความต้องการในโลกธุรกิจ',
    zhanxianshequ: 'แสดงพลังของการสร้างชุมชนและความเห็นร่วมกันในการปฏิบัติงาน',
    sectionmsg1: 'CITADEL AI เป็นพันธมิตรชุมชนที่รวมผู้นำด้านบล็อกเชน นวัตกรรม นักพัฒนาและธุรกิจระหว่างประเทศ ซึ่งเป็นตัวเปลี่ยนทางในการเติบโตและความเจริญของเทคโนโลยีบล็อกเชนของ CITADEL AI ด้วยการพัฒนาโอกาสธุรกิจใหม่ ส่งเสริมการประยุกต์ใช้ในอุตสาหกรรมและการเรียนรู้และร่วมมือ',
    sectionmsg2: 'เรารวบรวมชุมชนโลกที่เชื่อมต่อกับ CITADEL AI ที่มีความเชื่อมั่นอย่างสมบูรณ์ และหวังว่าจะให้คำแนะนำ การสนับสนุนและการบริหารจัดการในเทคโนโลยีบล็อกเชนและเทคโนโลยีใหม่ๆสำหรับธุรกิจ',
    zhize: 'ภารกิจของพันธมิตร',
    zhizemsg: 'CITADEL AI เป็นกำลังหลักของนิเวศ CITADEL AI ที่ให้ความสำคัญกับความไร้ศูนย์กลาง โดยผ่านทางหลายช่องทางเพื่อสนับสนุนการเติบโตและการพัฒนาของเทคโนโลยีและนิเวศของเครือข่ายบล็อกเชนของ CITADEL AI',
    shequ: 'แผนสร้างชุมชน',
    shequmsg1: 'ทำงานร่วมกันในอนาคตของ CITADEL AI',
    shequmsg2: 'CITADEL AI เป็นส่วนหนึ่งของการสร้างและดำเนินการชุมชน CITADEL AI อย่างต่อเนื่อง ในฐานะสมาชิก CITADEL AI ผู้ใช้จะเข้าร่วมชุมชนที่มีชีวิตชีวาและมีสมาชิกอื่นๆ เข้าร่วมในการตัดสินใจ การจัดกิจกรรม และร่วมกันสร้างแบลูเพริ้นของอนาคตของ CITADEL AI',
    shequmsg3: 'เดินหน้าสู่ยุค Web3 ที่ไร้ศูนย์กลาง',
    shequmsg4: 'รูปแบบการมีส่วนร่วมของชุมชนแบบกระจายอำนาจที่เรายึดถือนั้นสอดคล้องกับวิสัยทัศน์ของ Web3 ซึ่งไม่เพียงแต่ช่วยเพิ่มความโปร่งใสของระบบนิเวศ AI ของ CITADEL เท่านั้น แต่ยังช่วยให้มั่นใจได้ถึงความยุติธรรมและความเป็นสากลในการตัดสินใจทุกครั้ง',
    qiye: 'แผนธุรกิจ',
    qiyemsg1: 'ให้บริการโซลูชันบล็อกเชนที่เป็นการสร้างนวัตกรรม',
    qiyemsg2: 'CITADEL AI ร่วมงานกับธุรกิจทั่วโลกเพื่อสำรวจโอกาสที่ไม่มีที่สิ้นสุดของเทคโนโลยีบล็อกเชนของ CITADEL AI เรามุ่งมั่นที่จะให้บริการโซลูชันบล็อกเชนที่เป็นไปตามความต้องการของธุรกิจ เพื่อช่วยให้พวกเขาคงอยู่ในยุคเศรษฐกิจดิจิทัล',
    qiyemsg3: 'ใช้ศักยภาพของ CITADEL AI Mainnet อย่างเต็มที่',
    qiyemsg4: 'เราจะให้การสนับสนุนอย่างครบวงจรแก่ธุรกิจโดยช่วยให้พวกเขาเข้าใจและใช้ประโยชน์จากข้อดีทางเทคโนโลยีของ CITADEL AI Mainnet ในเรื่องความปลอดภัยของข้อมูลและความเชื่อถือได้อย่างสูงสุด',
    qukuaigonglian: 'เทคโนโลยีบล็อกเชนสาธารณะ',
    xiayidai: 'เครือข่ายพื้นฐานบล็อกเชนยุคใหม่ Web3',
    xinjingji: 'โซลูชันบล็อกเชนสำหรับยุคเศรษฐกิจใหม่',
    xinjingjimsg: 'CITADEL AI เป็นเครือข่ายบล็อกเชนที่ออกแบบมาเพื่อยุคเศรษฐกิจใหม่ โดยที่แบบจำลองเชื่อมโยงหลัก + ข้างเคียงหลายข้างของ CITADEL AI ให้พื้นที่สำหรับการติดตั้งและเรียกใช้แอปพลิเคชันกระจายได้อย่างอิสระ เพื่อให้ได้รับประสิทธิภาพของทรัพยากรและการใช้ทรัพยากรอย่างมีประสิทธิภาพ ผ่านการประมวลผลและกลไกเชื่อมโยงที่มีประสิทธิภาพ CITADEL AI สนับสนุนการซื้อขายที่ถี่ยิบมากขึ้น ในเวลาเดียวกัน เทคโนโลยี Cross-chain ยันฮสามารถให้ความมั่นใจในการสื่อสารและการโอนย้ายข้อมูลระหว่างโซลูชัน',
    kaifgongju: 'เครื่องมือการบริหารจัดการและการพัฒนาที่แข็งแกร่ง',
    kaifgongjumsg: "แบบจัดการที่แตกต่างกันจะต้องใช้โมเดลการจัดการที่แตกต่างกันไปด้วย เทคโนโลยี AI ของ CITADEL เข้าใจถึงจุดนี้อย่างลึกซึ้ง และด้วยเหตุนี้ได้มอบโมเดลการจัดการหลายรูปแบบ เช่น การจัดการด้วยสภา การจัดการทั่วประชาชน และการจัดการด้วยสมาคม ในเวลาเดียวกัน ระบบตั้งค่ารางวัลอย่างละเอียดเพื่อให้ระบบสามารถพัฒนาและมีสุขภาพดีต่อไปได้ นอกจากนี้ CITADEL AI ยังมีเครื่องมือ IDE ที่ก้าวหน้าเพื่อช่วยนักพัฒนาง่ายขึ้นในการพัฒนา การดีบัก และการติดตั้งแอปพลิเคชัน",
    zhongxinhua: "มุ่งมั่นในการเสริมสร้างการจัดการศูนย์กลางแบบกระจาย",
    zhongxinhuazeren: "CITADEL AI เป็นผู้ผลิตบล็อกหลักใน CITADEL AI Mainnet และรับผิดชอบหน้าที่สำคัญ",
    zhongxinhuali1: "รักษาความปลอดภัยของเครือข่าย",
    zhongxinhuali2: "ปรับปรุงการตัดสินใจของความเห็นส่วนรวม",
    zhongxinhuali3: "บำรุงรักษาการทำงานที่ดีที่สุดของโหนดฝั่งข้าง",
    zhongxinhuali4: "รักษาความมั่นคงของเครือข่าย",
    pianzhng: "เดินหน้าสู่บทใหม่",
    pianzhnglabel1: "ไม่ได้เป็นเพียงเครือข่ายบล็อกเชนเท่านั้น",
    pianzhngmsg1: "มันก็พยายามยอมรับความคิดเห็นขององค์กรที่มีการจัดการแบบกระจาย (DAO) ด้วย ด้วยเหตุนี้ CITADEL AI ได้สร้าง DAO ของตนเองและเริ่มโครงการรับสมัครโหนดทั่วโลก",
    pianzhnglabel2: "CITADEL AI มุ่งมั่นที่จะรับสมัคร 20 โหนดระดับสูงในระดับโลก",
    pianzhngmsg2: "แต่ละตัวแทนจะเป็นผู้เชี่ยวชาญในด้านต่าง ๆ ไม่ว่าจะเป็นหน่วยงานระดับนานาชาติหรือบุคคลดังในอุตสาหกรรมบางส่วน 20 ตัวแทนเหล่านี้จะมาเข้าร่วมและตัดสินใจเกี่ยวกับเรื่องสำคัญของนิเวศน์ CITADEL AI เพื่อสนับสนุน CITADEL AI ในการก้าวสู่ระดับที่สูงขึ้น",
    pianzhnglabel3: "การจัดการแบบกระจายเป็นการใช้งานที่สำคัญของเทคโนโลยีบล็อกเชน",
    pianzhngmsg3: "มันละทิ้งวิธีการจัดการแบบกลางเดิม โดยการตัดสินใจผ่านกระบวนการประชุมที่เป็นประชาธิปไตยและโปร่งใส ในโครงสร้าง DAO ทุกสมาชิกสามารถลงคะแนนโหวตตามส่วนร่วมในระบบหรือสิทธิ์ในการถือหุ้น เพื่อตัดสินใจร่วมกันในการเลือกทิศทางที่องค์กรจะเดินไปในอนาคต",
    pianzhnglabel4: "การสร้างมูลนิธิ CITADEL AI ก็เพื่อให้มีผู้มีส่วนร่วมมากขึ้น",
    pianzhngmsg4: "สนับสนุนในการพัฒนา CITADEL AI และเขียนเรื่องราวความยิ่งใหญ่ของ CITADEL AI อย่างร่วมกัน",
    mubiao: "เป้าหมายหลัก",
    mubiaolabel1: "การจัดการนิเวศน์",
    mubiaomsg1: "เลือกสนับสนุนและส่งเสริมโครงการที่มีศักยภาพสูงในระบบ CITADEL AI",
    mubiaolabel2: "การตรวจสอบเทคโนโลยี",
    mubiaomsg2: "รับผิดชอบในการยืนยันความปลอดภัยของเครือข่ายผ่านการผลิตบล็อกและการซื้อขาย",
    mubiaolabel3: "การประสานการทำงานข้ามเครือข่าย",
    mubiaomsg3: "สร้างความสัมพันธ์ร่วมกับตัวแทนอื่น ๆ ของบล็อกเชนเพื่อให้ CITADEL AI สามารถทำงานร่วมกันได้อย่างมีประสิทธิภาพและอัปเดตตามมาตรฐานใหม่ ๆ ของอุตสาหกรรม",
    mubiaolabel4: "การสร้างชุมชน",
    mubiaomsg4: "นำชุมชนไปสู่การพัฒนา สร้างสังคม สร้างพื้นที่สื่อสารอย่างเชิงบวก เพื่อสนับสนุนความเจริญรุ่งเรืองของนิเวศน์ CITADEL AI",
    mubiaolabel5: "การดำเนินและการจัดการ DAO",
    mubiaomsg5: "ควบคุมการส่งเสริมข้อเสนอการตั้งคำถามจากชุมชน การอภิปราย และการลงคะแนนเสียงเพื่อให้แน่ใจว่าข้อเสนอเหล่านี้ตรงกับพันธกิจและค่านิยมของ CITADEL AI",
    mubiaolabel6: "การระดมทุน",
    mubiaomsg6: "ควบคุมการระดมทุนเพื่อให้แน่ใจว่าระบบนิเวศน์ CITADEL AI จะเติบโตและมีความเสถียรทางการเงิน",
    time1: "ไตรมาสที่ 4 ปี 2023",
    time2: "ไตรมาสที่ 1 ปี 2024",
    time3: "ไตรมาสที่ 2 ปี 2024",
    time4: "ไตรมาสที่ 3 ปี 2024",
    time5: "ไตรมาสที่ 4 ปี 2024",
    time6: "2025",
    time7: "2026",
    timemsg1: "การรับสมัครให้เต็มที่ โดยมีเป้าหมายในการดึงดูดผู้ใช้งาน 10 หมื่นคนทั่วโลกใน 20 ประเทศ",
    timemsg2: "สนับสนุนการวิจัยและการนำไปใช้ของ Web3 โดยเชื่อมต่อระบบนิเวศน์ CITADEL AI กับ Web3 ที่พัฒนาอย่างต่อเนื่อง",
    timemsg3: "ช่วยเหลือในการสนับสนุนโครงการทุน Aclevate โดยการช่วยเผยแพร่ทีมพัฒนาเกมที่ได้รับการรับสมัครเข้า CITADEL AI เพื่อเพิ่มจำนวนผู้ติดตามและความนิยมในตลาด เพื่อให้ CITADEL AI มีอิทธิพลในสายงานเกมและการเงิน",
    timemsg4: "ด้วยการจัดงานประชุมออนไลน์และออฟไลน์ เช่น งานเทรดดิ้งและงานแสดงสินค้า และกิจกรรมในชุมชนอื่น ๆ เพื่อดึงดูดผู้ใช้งานและบริษัทให้เข้าร่วมกับ CITADEL AI",
    timemsg5: "เสริมสร้างความสัมพันธ์กับพันธมิตรระดับโลกเพื่อสนับสนุนการพัฒนาของ CITADEL AI ในระบบนิเวศน์",
    timemsg6: "สร้าง GameFi Alliance เพื่อให้ CITADEL AI เป็นผู้นำในการพัฒนาเกม Play-to-Earn และนวัตกรรม GameFi อื่น ๆ",
    timemsg7: "ประสบความสำเร็จในการดึงดูดผู้ใช้งาน 500,000 คนทั่วโลก ครอบคลุมอย่างน้อย 50 ประเทศ ช่วยให้สามแสง AI เพิ่มโครงการใหม่ใน Ecosystem อย่างน้อย 30 โครงการ และขยายและเพิ่มความสามารถและความหลากหลายของ CITADEL AI Ecosystem อย่างต่อเนื่อง",
    timemsg8: "ช่วยจัดตั้งศูนย์บ่มเพาะ CITADEL AI เพื่อสนับสนุนและขับเคลื่อนโครงการนวัตกรรมใน CITADEL AI Public Chain",
    timemsg9: "ก้าวสู่ SocialFi Ecology และ CITADEL AI ก้าวสู่การเป็น Social Finance และนวัตกรรมที่เกี่ยวข้อง",
    timemsg10: "จัดตั้ง CITADEL AI Academy เพื่อเป็นแหล่งเรียนรู้และฝึกอบรมเพื่อเพิ่มพูนความรู้และทักษะด้านบล็อกเชนของสมาชิกในชุมชน",
    timemsg11: "ริเริ่มโครงการ Community Bounty Program เพื่อสร้างแรงจูงใจให้ผู้คนเข้ามามีส่วนร่วมในการสร้างระบบนิเวศน์ของ CITADEL AI มากขึ้น",
    timemsg12: "ประสบความสำเร็จในการสรรหาผู้ใช้งาน 1 ล้านคนทั่วโลกครอบคลุม 100 ประเทศ",
    timemsg13: "สร้าง CITADEL AI Ecosystem ให้เป็น Blockchain Ecosystem ที่ทรงพลังและหลากหลายที่สุดในโลก",
    genzongtitle: "Defi โรบอตติดตามสาระสำคัญการขุดเหมืองลักษณะนิยม",
    genzonglabel: "เครื่องมือสุดท้ายสำหรับการตรวจสอบการลงทุนและการส่งผลตอบแทนสูงสุด",
    genzongmsg1: "โรบอตของเราที่ออกแบบอย่างพิถีพิถันสามารถติดตามและวิเคราะห์ผลตอบแทนจากเครือข่ายการเงินที่ไม่มีศูนย์กลาง (DeFi) และเครื่องมือการลงทุนและกลยุทธ์ต่างๆ",
    genzongmsg2: "นักลงทุนสามารถได้รับข้อมูลสำคัญเกี่ยวกับผลการลงทุนและความสามารถในการทำกำไรจากโรบอตของเราที่ทันสมัยที่สุด รวมถึงสามารถเข้าถึงตัวชี้วัดผลตอบแทนล่าสุด ประเมินประสิทธิภาพกลยุทธ์ และตัดสินใจได้อย่างมีสติ",
    genzongmsg3: "ไม่ว่าจะเป็นการจำนองเหรียญ การขุดเหรียญ หรือการเข้าร่วมสระเงินสด โรบอตของเราสามารถติดตามสถานการณ์การลงทุนของคุณอย่างใกล้ชิด และให้ข้อมูลและการวิเคราะห์แบบเรียลไทม์อย่างละเอียด",
    genzongmsg4: "อย่าพลาดโอกาสในการทำกำไรสูงสุด",
    trade: "โรบอตการซื้อขาย DeFi",
    tradelable: "แนะนำโรบอตการซื้อขายอัตโนมัติที่ทันสมัยที่สุดในโลก",
    chuangjian: "สร้าง",
    jiaoyisuanfa: "อัลกอริทึมการซื้อขาย",
    shiyong: "ใช้งาน",
    zhibiao: "ตัวชี้วัดเทคโนโลยีที่ปรับแต่งได้ของเรา",
    jiedu: "อ่านและแปลง่าย",
    xinhao: "สัญญาณที่ง่ายดาย",
    trademsg1: "เราใส่โรบอตการซื้อขายที่เราพัฒนาเองลงในเทอร์มินัลที่มีฟังก์ชันครบครัน และดำเนินการในหลายแพลตฟอร์มการซื้อขายแบบไม่มีศูนย์กลาง เพื่อให้ลูกค้าได้รับการดำเนินการอย่างมีประสิทธิภาพและแม่นยำ",
    trademsg2: "เราจะรวมระบบกับ ERC Chain ในการอัปเกรดครั้งต่อไปเพื่อเพิ่มปริมาณการซื้อขาย พร้อมทั้งเพิ่มกำไรให้ลูกค้า และให้เครือข่ายที่เสถียรช่วยให้ลูกค้าตอบสนองกับตลาดสกุลเงินดิจิทัลที่เปลี่ยนไปอย่างไว้วางใจได้",
    kefu: "Telegram ฝ่ายบริการลูกค้า"
  },
  HomeFramework: {
    bannerTitle: 'เปิดโลกเชิงปริมาณที่ไม่ธรรมดา',
    bannerMsg1: 'สร้างแพลตฟอร์มความมั่งคั่งเชิงปริมาณอัจฉริยะรุ่นใหม่ในด้าน AI',
    bannerMsg2: 'Edison AI พลังลึกลับจากส่วนลึกของ AI ช่วยให้บุคคลธรรมดาสามารถสร้างประกายระยิบระยับได้เช่นกัน',
    bannerMsg3: 'ความเป็นธรรม / เสรีภาพ / ความเชื่อ / อนาคต',
    aboutTitle1: 'หลายระดับ',
    aboutTitle2: 'หลายมุม',
    aboutTitle3: 'ข้อมูลหลาย',
    aboutMsg1: 'รวมโมเดลใน 3 ระดับหลักของการจัดสรรสินทรัพย์, การเลือกอุตสาหกรรม, การเลือกสินทรัพย์ที่เฉพาะเจาะจง',
    aboutMsg2: 'แนวคิดหลักของการลงทุนเชิงปริมาณประกอบด้วยหลายมุมมองเช่นวัฏจักรมหภาคโครงสร้างตลาดการประเมินมูลค่าการเติบโตคุณภาพของผลกำไรการคาดการณ์ผลกำไรของนักวิเคราะห์ความเชื่อมั่นของตลาด',
    aboutMsg3: 'นั่นคือการประมวลผลข้อมูลมหาศาล',
    robotTitle: 'หุ่นยนต์เชิงปริมาณ Edison AI',
    robotMsg: 'เป็นผู้ช่วยทางการเงินอัจฉริยะรุ่นใหม่ที่รวมการวิเคราะห์ข้อมูลขนาดใหญ่ของ AI ขั้นสูงเพื่อให้การวิเคราะห์แนวโน้มของตลาดที่แม่นยำและช่วยให้คุณตัดสินใจลงทุนได้อย่างชาญฉลาด กลยุทธ์การลงทุนที่ปรับให้เหมาะสมกับความต้องการและความเสี่ยงที่ยอมรับได้ ให้ผลตอบแทนการลงทุนที่สูงขึ้น',
    platformTitle: 'วิสัยทัศน์ของแพลตฟอร์ม',
    platformMsg: 'ใช้ AI และเทคโนโลยี Memory Blockchain เพื่อสร้างแพลตฟอร์มการเงินที่ครอบคลุมและสร้างสภาพแวดล้อมการลงทุนที่ปลอดภัยโปร่งใสเป็นธรรมและมีประสิทธิภาพสำหรับทุกคน',
    footerMsg: 'Edison AI Wealth Center เป็นแพลตฟอร์มการจัดการความมั่งคั่งที่ใช้ปัญญาประดิษฐ์ AI ข้อมูลขนาดใหญ่และเทคโนโลยีห่วงโซ่หน่วยความจำ มุ่งเน้นไปที่นวัตกรรม AI แบบโอเพนซอร์สและกระจายอํานาจโดยมีมูลนิธิ Edison AI F.D.T UK Limited เป็นรากฐานที่สําคัญและได้รับการสนับสนุนจากฐานข้อมูล AI Big Model, AI Supercomputing และ TPS ข้อมูลจํานวนมาก เพื่อสร้างชุดรหัสรันที่ชาญฉลาดที่สมบูรณ์แบบเพื่อให้ AI Quantity นั้นยุติธรรมปลอดภัยและรวดเร็วอย่างแท้จริง',
    menu2: 'ภารกิจของฉัน',
    menu3: 'บิลของฉัน',
    menu4: 'กำไรของฉัน',
    menu5: 'ทรัพย์สินของฉัน',
    menu6: "ฝ่ายบริการลูกค้าของฉัน",
    menu7: "กลยุทธ์เชิงปริมาณ",
  },
  HomeService: {
    title: 'บริการที่เรานำเสนอ',
    msg: 'ระบบ Edison มีฐานข้อมูลโมเดลขนาดใหญ่ของ AI ที่แข็งแกร่ง Two Sigma ให้การสนับสนุนด้านเทคนิคและการสนับสนุนข้อมูลอย่างเต็มที่ เบื้องหลังคือการสนับสนุนข้อมูลจํานวนมากและการคํานวณจํานวนมาก มีแหล่งข้อมูลมากกว่า 10,000 แหล่ง CPU มากกว่า 95,000 1000TB 22 ล้านกิกะไบต์ข้อมูลที่เพิ่มขึ้นทันทีความเร็วในการประมวลผลต่อวินาทีสามารถเข้าถึง 1 X 10 ^ 14 ความจุฐานข้อมูลสูงถึง 1695 TB โดยไม่มีอุปสรรคเมื่อใช้แนวคิดการลงทุนที่ซับซ้อนที่สุด',
    serviceTitle1: 'บริการเชิงปริมาณ',
    serviceTitle2: 'แก้ Pain Point',
    serviceTitle3: 'ระบบการซื้อขายอัจฉริยะ',
    serviceTitle4: 'ศูนย์รวมภูมิปัญญา',
    serviceTitle5: 'ข้อดีของการเทรด',
    serviceTitle6: 'เทคโนโลยีการซื้อขาย',
    list1Msg1: 'แพลตฟอร์มพื้นฐานของ AI ระดับองค์กรอัลกอริทึมการซื้อขายเชิงปริมาณชั้นนำการเรียนรู้ของเครื่องอัตโนมัติการเพิ่มประสิทธิภาพพารามิเตอร์พิเศษการเพิ่มประสิทธิภาพการประมวลผลเชิงปริมาณของ AI อย่างรวดเร็ว',
    list1Msg2: 'มาตรฐาน QA ที่เป็นกรรมสิทธิ์ของ PB Class ข้อมูลทางการเงิน + ข้อมูลขนาดใหญ่สำหรับการลงทุนใหม่รองรับการปรับแต่งข้อมูลประเภทต่างๆเพื่อตอบสนองความต้องการการลงทุนที่แตกต่างกัน',
    list1Msg3: '2000 + ไลบรารีองค์ประกอบพื้นฐาน, AI สนับสนุนการขุดเพิ่มเติมอนุพันธ์ก้าวไปข้างหน้าหนึ่งก้าวและได้รับโอกาสทางการตลาดมากขึ้น',
    list1Msg4: 'Expression Engine สร้างไลบรารีของปัจจัยที่หลากหลายซึ่งสนับสนุนการวิเคราะห์ปัจจัยอย่างครอบคลุมและค้นพบปัจจัยที่มีประสิทธิภาพได้เร็วขึ้น',
    list1Msg5: 'สภาพแวดล้อมการพัฒนาภาพแบบแยกส่วนซึ่งรวมเข้ากับรุ่น Python Code ได้อย่างราบรื่นเพิ่มประสิทธิภาพในการพัฒนากลยุทธ์',
    list1Msg6: 'การค้นหาพารามิเตอร์พิเศษ, เครื่องมือเพิ่มประสิทธิภาพการรวมกัน, การฝึกกลิ้ง, การวิเคราะห์แหล่งที่มาและอื่น ๆ อีกมากมาย, อุปกรณ์ที่กำหนดเองสำหรับผู้เล่นระดับไฮเอนด์',
    list2Msg1: 'ตั้งแต่ปีพ. ศ. 2564 EDISON AI ได้รับความร่วมมืออย่างลึกซึ้งกับการแลกเปลี่ยนหลัก Coin\'an, Ouyi, Fire Coin เพื่อหลีกเลี่ยงสถานการณ์ของการแลกเปลี่ยน',
    list2Msg2: 'ตามค่าเริ่มต้น ลูกค้าควรซื้อขายเฉพาะเหรียญเก่ากระแสหลัก หลีกเลี่ยงการใช้อัลท์คอยน์และเหรียญยอดนิยม และหลีกเลี่ยงเหตุการณ์พายุฝนฟ้าคะนองที่คล้ายคลึงกันของ LUNA',
    list2Msg3: 'จะไม่ได้รับผลกระทบจากข่าวที่สื่อมวลชนสื่อสารใดๆ ร่วมกันตัดต้นหอม และดำเนินการตามนโยบายอย่างเคร่งครัดและครอบคลุมเพื่อหลีกเลี่ยงผลกระทบทางอารมณ์ด้านลบของผู้ใช้งาน',
    list2Msg4: 'หุ่นยนต์นี้ถูกปรับใช้ในบริการคลาวด์โดยไม่ใช้คอมพิวเตอร์และเครือข่ายท้องถิ่นของคุณและใช้กลยุทธ์โดยอัตโนมัติตลอด 24 ชั่วโมงเพื่อแก้ไขปัญหาด้านพลังงานของลูกค้า',
    list2Msg5: '24 ชั่วโมงอัตโนมัติอัจฉริยะมิลลิวินาทีคำนวณและความเร็วในการซื้อขายจับภาวะฉุกเฉินและแก้ปัญหาความเร็วเทียมในตลาดขนาดใหญ่ไม่สามารถตามได้',
    list2Msg6: 'โหมดคณิตศาสตร์การเทรดที่ได้รับการปรับปรุงอย่างต่อเนื่องเป็นเวลาสามปีของ EDISON ในตัวเพื่อคำนวณ Smart Mode Margin Call โดยใช้ข้อมูลทางสถิติ Big Data ร่วมกับสูตรการคำนวณ',
    list3Msg1: 'ด้วยการติดตามข้อมูล Minute K-Line ทำให้ Take Profit Point และ Profit Point ถูกปรับเปลี่ยนอย่างชาญฉลาดเพื่อเพิ่มผลกำไรและลดต้นทุนในการซื้อ',
    list3Msg2: 'แก้ปัญหาการตกและปักหมุดแบบแขวนด้วยตนเองโดยกำหนดเส้นนาทีให้ขึ้นและลงมากกว่าค่าที่กำหนดคือปรับจุดพักหรือหยุด',
    list3Msg3: 'กลยุทธ์ที่ปรับแต่งได้ ได้แก่ Martingale, Flat Push Series, Fibonacci และอื่น ๆ เพื่อเพิ่มความเสี่ยงในการเล่นการพนัน Stallion',
    list3Msg4: 'โหมดอัจฉริยะที่เหมาะสำหรับการเริ่มต้นเพียงคลิกเดียวของ Xiaobai โหมด Pro + โหมดโควต้าของ Quantitative Master Assist เหมาะสำหรับทุกคน',
    list3Msg5: 'ตลาดหมีสามารถขายชอร์ต ตลาดกระทิงสามารถเปิดมากขึ้น ตลาดที่สั่นสะเทือนสามารถเปิดชอร์ตได้ ตลาดด้านเดียวสามารถเชื่อมโยงกับชอร์ตได้มากน้อยเพียงใดสำหรับสถานการณ์ที่แตกต่างกัน',
    list3Msg6: 'สำหรับการซื้อขายตำแหน่งในชุดต่าง ๆ การคำนวณจำนวนการซื้อขายแต่ละคำสั่งอย่างถูกต้องและหลีกเลี่ยงปัญหาที่ยุ่งยากในการคำนวณด้วยตนเองในระดับสูงสุด',
    list4Msg1: 'Wisdom Mathematics Model, Built-in EDISON Built-in Computing Model, ขึ้นอยู่กับราคาปัจจุบันและข้อมูลในอดีต, มีช่วงเวลาของความผันผวน, Wisdom Computing, การปรับ Margin Call ครั้งต่อไปแบบเรียลไทม์',
    list4Msg2: 'Wisdom Real-time Tracking K-Line นำไปใช้ในการติดตาม Take Profit Tracking ฟังก์ชั่นการเปิดตำแหน่งการปรับ Take Profit และ Margin Return แบบเรียลไทม์การขยายกำไรลดต้นทุน',
    list4Msg3: 'ตรวจสอบความลึกของการซื้อขายอย่างชาญฉลาด, ตรวจสอบความลึกของการแลกเปลี่ยนที่มีขีด จำกัด สูงสุดของการแลกเปลี่ยน, การแสวงหาราคาซื้อและขายที่ดีที่สุด',
    list4Msg4: 'ข้อมูลประวัติสถิติภูมิปัญญา, สถิติภูมิปัญญาการซื้อขายข้อมูลประวัติสกุลเงินและความผันผวน, สนับสนุนโหมดภูมิปัญญาการทำงานที่มั่นคง',
    list4Msg5: 'ฉลาดปรับความเร็วในการเทรด ปรับอัตราการเทรดตามภูมิปัญญาความผันผวนของตลาด ควบคุมราคาที่ดีที่สุดได้อย่างรวดเร็วในขณะที่เพิ่มประสิทธิภาพการทำงานที่มั่นคงของเซิร์ฟเวอร์',
    list5Msg1: 'ความเร็วในการซื้อขาย',
    list5Msg2: 'มิลลิวินาทีรวดเร็วและมีเสถียรภาพ',
    list5Msg3: 'เวลาพลังงาน',
    list5Msg4: 'ซื้อขายได้ตลอด 24 ชั่วโมงโดยไม่ต้องนอน',
    list5Msg5: 'ข้อมูลภูมิปัญญา',
    list5Msg6: 'การคำนวณตำแหน่งและการคำนวณข้อมูลการซื้อขายที่รวดเร็วและแม่นยำ',
    list5Msg7: 'รูปแบบการซื้อขาย',
    list5Msg8: 'รูปแบบการซื้อขายที่สมบูรณ์และมีเสถียรภาพ',
    list5Msg9: 'การดำเนินนโยบาย',
    list5Msg10: 'ดำเนินการตามยุทธศาสตร์ในภาพรวมอย่างเคร่งครัด',
    list5Msg11: 'การติดตามแบบเรียลไทม์',
    list5Msg12: 'แท็กแบบเรียลไทม์, การติดตามการเคลื่อนไหวที่ชาญฉลาด K-Line',
    list5Msg13: 'ผลกระทบทางอารมณ์',
    list5Msg14: 'ไม่ได้รับผลกระทบจากอารมณ์ของเจ้าของ',
    list5Msg15: 'อิทธิพลสื่อ',
    list5Msg16: 'อย่าหลงเชื่อข่าวปลอม',
    list5Msg17: 'ความสามารถในการทำกำไร',
    list5Msg18: 'กำไรระยะสั้นสามารถมีเสถียรภาพในระยะยาว',
    list6Msg1: 'เทคโนโลยีการซื้อขาย',
    list6Msg2: 'การวิจัยและพัฒนาอิสระที่แข็งแกร่งและมีเสถียรภาพการอนุมัติอุตสาหกรรมสูง',
    list6Msg3: 'ประวัติการทำงาน',
    list6Msg4: 'EDISON มุ่งเน้นการวิจัยเชิงปริมาณเป็นเวลา 8 ปีและได้รับการตีพิมพ์เป็นเวลา 3 ปี',
    list6Msg5: 'ค่าธรรมเนียม',
    list6Msg6: 'ตลาดโลกเป็นเอกภาพโปร่งใสไม่ปิดบัง',
    list6Msg7: 'รูปแบบการซื้อขาย',
    list6Msg8: 'อิสระ R & D รูปแบบการซื้อขายที่มั่นคงสองรูปแบบใหญ่',
    list6Msg9: 'สถานการณ์ของคณะกรรมการ',
    list6Msg10: 'ไม่',
    list6Msg11: 'การเลือกแพลตฟอร์ม',
    list6Msg12: 'รองรับเฉพาะ Coin An Oyi Huobi ลูกค้าสามารถเลือกได้ด้วยตัวเอง',
    list6Msg13: 'สถานการณ์ความมั่นคง',
    list6Msg14: 'EDISON แปดปีการรับประกันเชิงปริมาณที่มั่นคง',
    list6Msg15: 'การปรับปรุงการเพิ่มประสิทธิภาพ',
    list6Msg16: 'สี่ปีให้ทันกับการปรับปรุงการเพิ่มประสิทธิภาพตลาด',
    list6Msg17: 'จำนวนสมาชิกทั้งหมด',
    list6Msg18: 'ฐานลูกค้าที่มั่นคงมีส่วนแบ่งการตลาด 70%+'
  },
  HomeStepper: {
    title: 'เอดิสัน',
    title2: 'แผนการพัฒนา',
    step1Msg1: 'ฐานข้อมูลขนาดใหญ่ของ Al ถูกสร้างขึ้นและฝึกฝน;',
    step1Msg2: 'จัดตั้งทีมปฏิบัติการเข้ารหัสเพื่อรับผิดชอบในการวางแทร็กที่เข้ารหัส',
    step2Msg1: 'การลงทุนเชิงปริมาณของปัญญาประดิษฐ์ AI เริ่มดําเนินการแล้ว',
    step2Msg2: 'สินทรัพย์ทางการเงินที่จัดการโดยปัญญาประดิษฐ์ AI มีการเติบโตอย่างรวดเร็ว',
    step2Msg3: 'มันเริ่มลงทุนในแพลตฟอร์มการซื้อขายสกุลเงินดิจิทัลที่มีคุณภาพสูงและโครงการ DEFI',
    step3Msg1: 'Two Sigma ลงทุนในมูลนิธิ Edison Al',
    step3Msg2: 'แพลตฟอร์มศูนย์ความมั่งคั่งปัญญาประดิษฐ์ของเอดิสันของมูลนิธิออนไลน์',
    step3Msg3: 'หุ่นยนต์รวม AI ของเอดิสันออกมา',
    step3Msg4: 'Edison AI DEFI เริ่มต้นบางส่วน;',
    step4Msg1: 'EdisonAI กระเป๋าสตางค์โซ่สาธารณะออนไลน์;',
    step4Msg2: 'เอดิสัน AI เข้ารหัสสังคมออนไลน์',
    step5Msg1: 'แอป EdisonAl Male Chain Eco ออนไลน์;'
  },
  Chongzhi: {
    title: 'เติมเงิน',
    bill: 'บิลของฉัน',
    chakan: 'ดู',
    placeholder: 'กรุณาใส่จำนวนการเติมเงิน',
    balance: 'ยอดเงินในกระเป๋าสตางค์',
    queding: 'ระบุ',
    guangbo: 'ความสำเร็จในการออกอากาศการซื้อขาย',
    message1: 'กรุณาใส่จำนวนการเติมเงิน',
    message2: 'ยอดเงินในกระเป๋าไม่เพียงพอ',
    message3: 'กำลังเทรด...',
    message4: 'กรุณาใส่จำนวนที่ถูกต้อง',
    xiugai: "การปรับเปลี่ยน",
    bangding: "คุณไม่ได้ผูกที่อยู่ TRC20 ผูกทันที",
    message5: "กรุณาป้อนที่อยู่ TRC20",
    message6: "แก้ไข...",
    message7: "กรุณาใส่จำนวนที่ถูกต้อง",
    message8: "ที่อยู่กระเป๋าสตางค์ TRON ที่คุณใช้อยู่ในปัจจุบันไม่ตรงกับที่อยู่ที่คุณผูกไว้ โปรดแก้ไขก่อนโอนเงิน",
    message9: "*กรุณาใช้ที่อยู่กระเป๋าสตางค์ที่ผูกไว้เพื่อโอนจำนวนเติมเงินไปยังที่อยู่รับเงิน",
    message10: "โปรดรอ 2 นาทีหลังจากการโอนเงินสำเร็จไปยังบัญชีโดยอัตโนมัติเมื่อบล็อกได้รับการยืนยัน!",
    shoubi: "ที่อยู่รับเงินของแพลตฟอร์ม",
    bangdingdizhi: "ผูกที่อยู่",
    copy: "คัดลอกที่อยู่",
    shouquan: "การอนุญาต",
    shouquanjine: "จำนวนเงินที่ได้รับอนุญาต",
    shouquantip: "จำนวนเงินที่ได้รับอนุญาตไม่เพียงพอโปรดมอบอำนาจก่อน",
    yishouquan: "ได้รับอนุญาตแล้ว",
    shouquanzhong: "กำลังอนุมัติ...",
    chongzhizhong: "กำลังเติมเงิน...",
    shouquanchenggogn: "การอนุญาตสำเร็จ"
  },
  Bill: {
    title: 'การเรียกเก็บเงิน',
    date: 'เวลา',
    leixing: 'ประเภท',
    coin: 'สกุลเงิน',
    num: 'ปริมาณ',
    finished: 'ไม่มีอีกแล้ว',
    all: 'ทั้งหมด',
    shouyi: 'ผลประโยชน์',
    chongzhi: 'เติมเงิน',
    tixian: 'ถอนเงินสด',
    zhuanzhang: 'โอนเงิน',
    zhuanhuan: 'การแปลง',
    zengjian: 'เพิ่มหรือลบ',
    xiaofei: 'ซื้อ'
  },
  NodeRecord: {
    title: 'บันทึกการซื้อ',
    date: 'เวลา',
    node: 'โหนด',
    amount: 'จำนวนเงิน',
    finished: 'ไม่มีอีกแล้ว'
  },
  Bonus: {
    title: 'ผลประโยชน์',
    date: 'เวลา',
    type: 'ประเภท',
    jine: 'จำนวนเงิน',
    finished: 'ไม่มีอีกแล้ว',
    jinri: 'รายได้วันนี้',
    leiji: 'กำไรสะสม'
  },
  Team: {
    title: 'รายการผลักดันตรง',
    address: 'ที่อยู่',
    jine: 'จำนวนเงิน',
    jibie: 'ระดับ',
    wu: 'ไม่มี',
    yeji: 'ประสิทธิภาพของโหนดทีม',
    jiedianyeji: "ประสิทธิภาพของโหนด",
    aiyeji: "ประสิทธิภาพของ AI"
  },
  User: {
    home: 'หน้าหลัก',
    chongzhi: 'ศูนย์เติมเงิน',
    bonus: 'กำไรของฉัน',
    ai: 'ข้อมูล AI',
    mining: 'ขุดเหมือง',
    notice: 'ประกาศ'
  },
  Notice: {
    title: 'ประกาศ',
    details: 'รายละเอียดการประกาศ'
  },
  TabBar: {
    home: 'ตลาด',
    ai: 'AI พยาน',
    mining: 'ศูนย์ความมั่งคั่ง',
    team: 'ทีม',
    user: 'ของฉัน'
  },
  Market: {
    dagnqianjiage: 'ราคาปัจจุบัน',
    chushi: 'ราคาเริ่มต้น',
    mairu: 'ซื้อ',
    maichu: 'ขาย',
    liudongxing: 'สภาพคล่อง',
    xinxi: 'ข้อมูล',
    kongtou: "รางวัล CCAI AirDrop",
    dangqian: "ทั้งหมด",
    daibixiaohui: 'การทำลาย CCAI โทเค็น',
    xiaohuizongliang: 'จำนวนทั้งหมดของ CCAI ที่ถูกทำลาย',
    chubirenshu: 'จำนวนผู้ถือ CCAI',
    faxingzongliang: 'จำนวนทั้งหมดของ CCAI ที่ออก',
    jiedian: 'โหนด',
    jine: 'จำนวนเงิน',
    suanli: 'แรงขุด',
    zuori: "เมื่อวานนี้",
    jinri: "วันนี้"
  },
  AiData: {
    zongjiazhi: 'มูลค่ารวมของล็อค (USD)',
    celue: 'นโยบายตารางอัลกอริทึม',
    totalMsg: 'Citadel เป็นหนึ่งในกองทุนป้องกันความเสี่ยงเชิงปริมาณที่มีอิทธิพลมากที่สุดในโลกโดยใช้โมเดล AI และ Machine Learning ที่ซับซ้อนเพื่อจัดการสินทรัพย์และเพิ่มประสิทธิภาพการซื้อขายในตลาด บริษัทหลักทรัพย์ ซิตาเดล จำกัด แผนกธุรกรรมทางอิเล็กทรอนิกส์ซึ่งใช้เทคโนโลยี AI อย่างกว้างขวางเพื่อปรับปรุงประสิทธิภาพการดำเนินการธุรกรรม หุ่นยนต์ CITADEL AI จะกําหนดและปรับปรุงกลยุทธ์แบบเรียลไทม์และสร้างคําสั่งตามการเรียนรู้เชิงลึกและการตรวจสอบข้อมูลขนาดใหญ่ของธุรกรรมหลายร้อยรายการทุกวัน ให้การสนับสนุนทางเทคนิคและการสนับสนุนข้อมูลอย่างเต็มที่ เบื้องหลังคือการสนับสนุนข้อมูลจํานวนมากและการคํานวณขนาดใหญ่ มีแหล่งข้อมูลมากกว่า 10,000 แหล่ง CPU มากกว่า 95,000 1000TB',
    botTitle: 'ปริมาณการสั่งซื้อ Arbitrage',
    jiangli: 'รางวัล',
    rilirun: 'กำไรรายวัน',
    yuelirun: 'กำไรรายเดือน',
    jiage: 'ราคา',
    zjc: "กองทุนรวม",
    ailh: "AI ปริมาณ",
    aijy: "การซื้อขาย AI",
    poolStats: "สถิติสระว่ายน้ำ",
    arbitrageStats: "สถิติ Arbitrage",
    zongyue: "ยอดคงเหลือทั้งหมด",
    baozhengjin: "มาร์จิ้น (USDT)",
    qianbaoyue: "ยอดคงเหลือ (USDT)",
    yunxingyingkui: "กำไรและขาดทุน (USDT)",
    zongyingkui: "กำไรและขาดทุนทั้งหมด (USDT)",
    name: "สกุลเงิน",
    price: "ราคา",
    aiweituo: "AI มอบหมาย",
    aimingxi: "รายละเอียดการซื้อขาย AI",
    dingyue: "สมัครสมาชิก",
    jilu: "บันทึก",
    ziliaoku: "ศูนย์ข้อมูล",
    chengjiaoliang: "ปริมาณการซื้อขาย",
    jiaoyidui: "คู่ซื้อขาย",
    chengjiaotu: "แผนภูมิปริมาณการซื้อขาย",
    paixu: "เรียงลำดับ",
    bizhong: "สกุลเงิน",
    price24: "ราคา (24 ชั่วโมง)",
    wending: "การเชื่อมต่อที่เสถียร",
    zhichi: "การสนับสนุนออนไลน์"
  },
  Mining: {
    mingcheng: 'ชื่อโทเค็น',
    fuhao: 'สัญลักษณ์โทเค็น',
    gongyingliang: 'อุปทานทั้งหมด',
    qishi: 'ราคาเริ่มต้น',
    jilu: 'บันทึกการมอบหมาย',
    chakan: 'ดู',
    aibot: 'หุ่นยนต์ AI',
    rishouyi: 'กำไรรายวัน',
    weituojine: 'จำนวนเงินที่ได้รับมอบหมาย',
    weituo: 'การมอบหมาย',
    aggregation: 'รวมบอท',
    placeholder1: 'กรุณาใส่จำนวนเงิน',
    queren: 'ยืนยัน',
    jijiangkaifang: 'เปิดเร็ว ๆ นี้',
    echartsName1: 'การจัดสรรชุมชน',
    echartsName2: 'การกำหนดโหนด',
    echartsName3: 'การจัดสรรมูลนิธิ',
    echartsName4: 'บ่อแร่ Xuanli',
    placeholder2: 'กรุณากรอก',
    loading: 'ในการมอบหมาย ...',
    jiqiren1: "S1 หุ่นยนต์",
    jiqiren2: "S2 หุ่นยนต์",
    jiqiren3: "S3 หุ่นยนต์",
  },
  TeamCount: {
    tuijian: 'คำแนะนำ',
    tuijianren: 'อ้างอิงของฉัน',
    lianjie: 'เชิญเข้าร่วม',
    tuandui: 'ทีม',
    teamCount: 'จำนวนผู้ลงทะเบียนทีม',
    liebiao: 'รายการผลักดันตรง',
    usYejiTotal: 'ประสิทธิภาพ AI ส่วนบุคคล',
    teamYejiTotal: 'ประสิทธิภาพของทีม AI',
    rtaShouyi: 'รายได้จาก AI',
    rtbShouyi: 'รายได้รวม',
    ktDay: 'ปริมาณ AirDrop วัน',
    ktTotal: 'กำไร IDO',
    jiedianyeji: 'ประสิทธิภาพของโหนดทีม',
    usjiedianyeji: 'ประสิทธิภาพของโหนดส่วนบุคคล',
    usxh: 'การทำลายพอลิเมอร์ส่วนบุคคล',
    teamxh: 'ทำลายการรวมทีม',
    jiedianshouyi: 'กำไรโหนด',
    youxiao: "จำนวนทีมที่มีประสิทธิภาพ"
  },
  Wallet: {
    total: 'สินทรัพย์ทั้งหมด',
    bill: 'บิลของฉัน',
    bonus: 'กำไรของฉัน',
    maichu: 'ขาย',
    chongzhi: 'เติมเงิน',
    tixian: 'ถอนเงินสด',
    mairu: 'ซื้อ',
    shandui: 'แฟลชไดรฟ์',
    keyong: 'ใช้ได้',
    dongjie: 'แช่แข็ง',
    zichan: "สินทรัพย์",
    zhuanzhang: 'โอนเงิน'
  },
  Convert: {
    mairu: 'ซื้อ',
    maichu: 'ขาย',
    shandui: 'แฟลชไดรฟ์',
    yue: 'ยอดคงเหลือ',
    placeholder1: 'กรุณาใส่ปริมาณ',
    edu: 'วงเงินการสมัคร',
    sxf: 'ค่าธรรมเนียมการจัดการ',
    ranliao: 'เชื้อเพลิงเทียบเท่า CCAI',
    querenmairu: 'ยืนยันการซื้อ',
    querenmaichu: 'ยืนยันการขาย',
    message1: 'แน่ใจนะว่าอยากจะซื้อ',
    message2: 'แน่ใจนะว่าอยากขาย',
    loading1: 'กำลังโหลด...',
    message3: 'คุณแน่ใจนะว่าต้องการแปลง?',
    loading2: 'แฟลชไดรฟ์ ...'
  },
  Tixian: {
    title: 'ถอนเงินสด',
    bill: 'บิลของฉัน',
    chakan: 'ดู',
    select: 'กรุณาเลือกสกุลเงิน',
    numLabel: 'กรุณาใส่จำนวนการถอนเงิน',
    placeholder: 'จำนวนการถอนเงิน',
    addressLabel: 'กรุณากรอกที่อยู่การถอนเงิน',
    placeholder2: 'ที่อยู่ถอนเงิน',
    yue: 'ยอดเงินในกระเป๋าสตางค์',
    sxf: 'ค่าธรรมเนียมการจัดการ',
    queding: 'ระบุ',
    message1: 'กรุณาเลือกสกุลเงิน',
    message2: 'กรุณาใส่จำนวนการถอนเงิน',
    message3: 'คุณแน่ใจนะว่าอยากจะถอนเงิน',
    loading: 'ถอนเงินสด...'
  },
  RobotUserRecord: {
    title: 'บันทึกการมอบหมาย',
    date: 'เวลา',
    robot: 'หุ่นยนต์',
    jine: 'จำนวนเงิน',
    state: 'สถานะ',
    finished: 'ไม่มีอีกแล้ว',
    shouyi: 'ในผลประโยชน์',
    shuhui: 'แลก',
    yijieshu: 'จบแล้ว',
    yishuhui: 'แลกแล้ว',
    empty: 'ไม่มีการบันทึกชั่วคราว',
    message: 'แน่ใจนะว่าอยากไถ่ถอน',
    loading: 'ในการไถ่ถอน ...',
    ranshao: "เชื้อเพลิง",
    suanli: "พลังแฮช"
  },
  NodePower: {
    title: 'แรงนับโหนด',
    date: 'เวลาโบนัส',
    jine: 'จำนวนเงินรางวัล',
    finished: 'ไม่มีอีกแล้ว'
  },
  Record: {
    cz: 'บันทึกการเติมเงิน',
    tx: "บันทึกการถอนเงิน",
    shijian: "เวลา",
    jine: "จำนวนเงิน",
    hash: "แฮชแลกเปลี่ยน",
    zz: "บันทึกการโอนเงิน",
    suserid: "ผู้รับเงิน"
  },
  Zhuanzhang: {
    title: "โอนเงิน",
    bill: "บันทึกการโอนเงิน",
    chakan: "ดู",
    select: "กรุณาเลือกสกุลเงิน",
    numLabel: "กรุณาใส่จำนวนการโอนเงิน",
    placeholder: "จำนวนการโอนเงิน",
    addressLabel: "กรุณากรอกที่อยู่ผู้รับเงิน",
    placeholder2: "ที่อยู่ผู้รับเงิน",
    yue: "ที่อยู่ผู้รับเงิน",
    sxf: "ค่าธรรมเนียมการจัดการ",
    queding: "ระบุ",
    message1: "กรุณาเลือกสกุลเงิน",
    message2: "กรุณาใส่จำนวนการโอนเงิน",
    message3: "คุณแน่ใจนะว่าต้องการโอนเงิน?",
    loading: "ในการโอนเงิน..."
  },
  AiTradeDetail: {
    title: "รายละเอียดธุรกรรม",
    pingcang: "การปิด",
    jiage: "ราคา (USDT)",
    num: "ปริมาณ (USDT)",
    shouyi: "ผลประโยชน์",
    yongxu: "ตลอดไป"
  },
  Service: {
    shuruwenti: "กรุณากรอกคำถามของคุณ",
    bunengweikong: "เนื้อหาของข้อความต้องไม่ว่างเปล่า",
    shangchuanshibai: "การอัพโหลดรูปภาพล้มเหลว",
    message: "กรุณาอัพโหลดรูปภาพภายใน 5M",
    message2: "กรุณาอัพโหลดรูปภาพในรูปแบบ jpg"
  },
  Subscribe: {
    title: "การสมัครสมาชิก",
    zhineng: "การซื้อขายปัญญาประดิษฐ์",
    zongdingyue: "ยอดรวมการสมัครสมาชิก",
    zonghuoyue: "ยอดรวมที่ใช้งาน",
    zhungtai: "สถานะ",
    shuchu: "การส่งออกสูงสุด",
    zhanghu: "บัญชี",
    beidongshouru: "รายได้Passive",
    zhudongshouru: "รายได้เชิงบวก",
    zongshouru: "รายได้ทั้งหมด",
    mima: "รหัสผ่าน"
  },
  aiLogin: {
    title: "ยินดีต้อนรับกลับมาอีกครั้ง!",
    label: "โปรดป้อนบัญชีของคุณเพื่อเข้าสู่ระบบ",
    account: "บัญชี",
    password: "รหัสผ่าน",
    check: "จดจำฉัน",
    submit: "เข้าสู่ระบบ",
    placeholder1: "ป้อนบัญชีของคุณ",
    placeholder2: "ป้อนรหัสผ่านของคุณ"
  },
  Trading: {
    tubiao: "แผนภูมิ",
    huobi: "สกุลเงิน",
    jiage: "ราคา",
    buy: "ซื้อ"
  },
  NavBar: {
    logout: "ออกจากระบบ",
    shouye: "หน้าแรก",
    positions: "ตำแหน่งที่ถืออยู่",
    closedPositions: "ตำแหน่งปิด",
    aiConfig: "การกำหนดค่า AI"
  },
  AiConfig: {
    tab1: "ภาพรวม",
    tab2: "ข้อมูล",
    tab3: "การลงทุน",
    tab4: "ประวัติการซื้อขาย",
    tab5: "บันทึกการฝากเงิน",
    biaoxian: "ผลงาน",
    guanyu: "เกี่ยวกับ",
    huichou: "ผลตอบแทนรวม",
    dingyuejine: "จำนวนเงินสมัครสมาชิกขั้นต่ำ",
    shuju: "ข้อมูล",
    lirun: "กำไรที่ดีที่สุด",
    lirunbaifenbi: "เปอร์เซ็นต์กำไรจากการซื้อขาย",
    jiaoyishu: "จำนวนการซื้อขายทั้งหมด",
    dingdan: "คำสั่งซื้อ",
    riqi: "วันที่",
    leixing: "ประเภทการซื้อขาย",
    jine: "จำนวนเงิน",
    huobi: "สกุลเงิน",
    maimai: "ซื้อ/ขาย",
    kaipiaojia: "ราคาที่เสนอ",
    kaidanriqi: "วันที่สั่งซื้อ",
    dangqianjiage: "ราคาปัจจุบัน",
    chengjiaoliang: "ปริมาณการซื้อขาย",
    yingkui: "กำไร/ขาดทุน",
    shoupanjia: "ราคาปิด",
    guandanriqi: "วันที่ปิด"
  },
  Pledge: {
    zhiya: "พันธบัตร",
    zhiyajilu: "บันทึกการพันธบัตร",
    xuanzezhouqi: "เลือกช่วงเวลา",
    qingxuanzezhouqi: "โปรดเลือกช่วงเวลา",
    zhiyashuliang: "จำนวนพันธบัตร",
    qingshurubeishu: "โปรดป้อนจำนวน 100 หรือคูณของ 100",
    zhifufangshi: "วิธีการชำระเงิน",
    querenzhiya: "ยืนยันการพันธบัตร",
    qingshurushuliang: "โปรดป้อนจำนวน",
    zhiyazhong: "กำลังพันธบัตร...",
    shijian: "เวลา",
    zhouqi: "รอบ",
    jine: "จำนวนเงิน",
    zhuangtai: "สถานะ",
    chezi: "ถอนการลงทุน",
    shouyizhong: "กำลังได้รับผลกำไร",
    yichezi: "ได้ถอนการลงทุนแล้ว",
    querenchezi: "คุณแน่ใจหรือไม่ว่าต้องการถอนการลงทุน?",
    chezizhong: "กำลังถอนการลงทุน..."
  }
}
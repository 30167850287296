module.exports =
{
  Base: {
    copy: 'Sao chép thành công',
    back: 'Quay lại',
    empty: 'Chưa có dữ liệu',
    empty2: 'Không có hồ sơ.',
    tishi: 'Mẹo',
    queding: 'Xác định',
    quxiao: 'Hủy bỏ',
    tijiao: 'Đang gửi......'
  },
  CheckRegister: {
    tuijianren: 'Giới thiệu',
    placeholder: 'Vui lòng nhập địa chỉ giới thiệu',
    register: 'Đăng ký',
    addressErr: 'Đăng ký nhận địa chỉ sai:',
    qingshuruyonghuming: "Vui lòng nhập tên người dùng",
    qingshuruyongmima: "Vui lòng nhập mật khẩu",
    dengluzhong: "Đăng nhập"
  },
  Home: {
    gonggao: "Thông báo",
    rules: 'Mô tả quy tắc',
    purchaseRecord: 'Hồ sơ mua hàng',
    select: 'Chọn cấp độ',
    amount: 'Số tiền mua',
    submit: 'Mua nút',
    chongzhi: 'Nạp tiền ngay',
    referrers: 'Giới thiệu của tôi',
    link: 'Mời kết nối',
    list: 'Danh sách Subscribe',
    address: 'Địa chỉ',
    listAmount: 'Số lượng subscribe',
    state: 'Trạng thái',
    completed: 'Đã hoàn thành',
    message1: 'Sao chép thành công',
    message2: 'Bạn có chắc chắn muốn mua?',
    message3: 'Đang mua......',
    down: 'Khoảng cách mua hàng',
    day: 'Ngày',
    hours: 'Khi',
    seconds: "giây",
    jieshu: 'Cuộc tranh giành đã kết thúc.',
    downTitle1: 'Đối tác của Edison AI Node',
    downTitle2: 'Tuyển dụng toàn cầu',
    zhitui: 'Danh sách đẩy thẳng',
    qianggouzhong: 'Giai đoạn 2 tranh mua',
    minutes: 'Phân chia',
    jiedian: 'Sức mạnh của Node',
    jifaqianneng: 'Kích thích tiềm năng vô hạn, tạo ra vô số khả năng',
    jinridapp: 'Vào DAPP',
    guanyu: 'Về chúng tôi',
    lianjiequkuai: 'Cầu nối khoảng cách giữa công nghệ Blockchain và yêu cầu thực tế của thế giới kinh doanh',
    zhanxianshequ: 'Hiển thị sức mạnh của xây dựng cộng đồng và sự nhất quán trong thực tế',
    sectionmsg1: 'CITADEL AI là một liên minh cộng đồng toàn cầu gồm các nhà lãnh đạo, nhà sáng tạo, nhà phát triển và doanh nghiệp quốc tế trong lĩnh vực Blockchain. Liên minh sẽ là một xúc tác cho sự phát triển và thịnh vượng của Blockchain CITADEL AI thông qua việc phát triển cơ hội kinh doanh mới, thúc đẩy ứng dụng ngành và hợp tác và học hỏi.',
    sectionmsg2: 'Chúng tôi tập hợp cộng đồng toàn cầu tin tưởng vào CITADEL AI và hy vọng cung cấp hướng dẫn, hỗ trợ và quản lý về công nghệ Blockchain và công nghệ mới cho các doanh nghiệp.',
    zhize: 'Nhiệm vụ của liên minh',
    zhizemsg: 'CITADEL AI là lực lượng cốt lõi của hệ sinh thái CITADEL AI, tuân thủ tinh thần phi tập trung và đẩy mạnh sự phát triển mạnh mẽ của công nghệ và sinh thái chuỗi khối công cộng CITADEL AI thông qua nhiều cách khác nhau.',
    shequ: 'Kế hoạch cộng đồng',
    shequmsg1: 'Xây dựng tương lai CITADEL AI cùng nhau',
    shequmsg2: 'CITADEL AI tiếp tục đóng vai trò quan trọng trong xây dựng và vận hành cộng đồng CITADEL AI. Là thành viên của CITADEL AI, người dùng sẽ được tham gia vào một cộng đồng sôi động, tham gia vào quyết định, tổ chức hoạt động và cùng nhau tạo ra bản thiết kế tương lai của CITADEL AI.',
    shequmsg3: 'Hướng tới thời đại Web3 phi tập trung',
    shequmsg4: 'Mô hình tham gia cộng đồng phi tập trung mà chúng tôi theo đuổi hoàn hảo phù hợp với tầm nhìn của Web3, không chỉ tăng cường tính minh bạch của hệ sinh thái CITADEL AI mà còn đảm bảo tính công bằng và phổ quát của mỗi quyết định.',
    qiye: 'Kế hoạch doanh nghiệp',
    qiyemsg1: 'Cung cấp giải pháp Blockchain sáng tạo cho doanh nghiệp',
    qiyemsg2: 'CITADEL AI hợp tác sâu rộng với các doanh nghiệp trên toàn thế giới để khám phá tiềm năng vô tận của công nghệ Blockchain CITADEL AI. Chúng tôi cam kết cung cấp các giải pháp Blockchain có định hướng cho doanh nghiệp, giúp họ duy trì sức cạnh tranh trong thời đại kinh tế số.',
    qiyemsg3: 'Tận dụng tối đa tiềm năng của mạng chính CITADEL AI',
    qiyemsg4: 'Chúng tôi cũng sẽ cung cấp hỗ trợ toàn diện cho doanh nghiệp để giúp họ hiểu và tận dụng ưu điểm công nghệ của mạng chính CITADEL AI và đảm bảo trải nghiệm tốt nhất trong hệ sinh thái CITADEL AI.',
    qukuaigonglian: 'Blockchain công cộng',
    xiayidai: 'Thế hệ kế tiếp của Web3 - Hệ sinh thái công cộng phi tập trung',
    xinjingji: 'Giải pháp Blockchain cho thời đại kinh tế mới',
    xinjingjimsg: 'CITADEL AI, một mạng lưới Blockchain được thiết kế đặc biệt cho nền kinh tế mới, kiến trúc chính + nhiều mạng phụ của nó cung cấp không gian để triển khai và vận hành ứng dụng phân tán độc lập, từ đó đạt được tối ưu hóa và sử dụng hiệu quả tài nguyên. Thông qua tính toán song song hiệu quả và cơ chế thống nhất, CITADEL AI cung cấp sự hỗ trợ mạnh mẽ cho giao dịch tần suất cao. Đồng thời, công nghệ giao cắt đảm bảo việc truyền thông và chuyển giá trị giữa các chuỗi khối.',
    kaifgongju: 'Công cụ quản lý và phát triển mạnh mẽ',
    kaifgongjumsg: 'Các kịch bản kinh doanh khác nhau đòi hỏi các mô hình quản lý khác nhau và CITADEL AI hiểu rõ điều này và do đó cung cấp nhiều mô hình quản lý như quản trị hội đồng, quản trị toàn dân và quản trị hiệp hội. Đồng thời, để đảm bảo sự phát triển liên tục và lành mạnh của hệ thống, CITADEL AI thiết kế một mô hình kích thích tinh vi. Ngoài ra, CITADEL AI còn cung cấp các công cụ IDE tiên tiến để giúp nhà phát triển dễ dàng phát triển, gỡ lỗi và triển khai ứng dụng.',
    zhongxinhua: 'Đóng góp cho quản trị phi tập trung',
    zhongxinhuazeren: 'Là nhà sản xuất khối cho mạng chính CITADEL AI, CITADEL AI có trách nhiệm quan trọng.',
    zhongxinhuali1: 'Đảm bảo an ninh mạng',
    zhongxinhuali2: 'Tối ưu quyết định thống nhất',
    zhongxinhuali3: 'Duy trì hoạt động tốt nhất của nút mạng phụ',
    zhongxinhuali4: 'Giữ cho mạng luôn ổn định',
    pianzhng: 'Bước vào chương mới',
    pianzhnglabel1: 'Không chỉ là mạng blockchain',
    pianzhngmsg1: 'Nó cũng nỗ lực nhận thức về ý tưởng tự quản lý phi tập trung (DAO). Để làm điều này, CITADEL AI đã thành lập DAO riêng của mình và khởi động chương trình tuyển dụng các nút toàn cầu trên cơ sở này.',
    pianzhnglabel2: 'CITADEL AI cam kết tuyển dụng 20 nút cấp cao trên toàn cầu',
    pianzhngmsg2: 'Mỗi đại diện đều là những người xuất sắc trong các lĩnh vực khác nhau, có thể là tổ chức quốc tế hoặc những người nổi tiếng được tôn vinh trong một lĩnh vực cụ thể. 20 đại diện này sẽ tham gia và tham gia vào việc quyết định các vấn đề quan trọng về hệ sinh thái CITADEL AI, đẩy mạnh phát triển CITADEL AI lên một tầm cao mới.',
    pianzhnglabel3: 'Tự quản lý phi tập trung là ứng dụng quan trọng của công nghệ blockchain',
    pianzhngmsg3: 'Nó loại bỏ phương thức quản lý tập trung truyền thống, giúp quá trình ra quyết định trở nên dân chủ và minh bạch hơn. Trong kiến trúc DAO, mỗi thành viên có thể bỏ phiếu dựa trên đóng góp hoặc quyền sở hữu trong hệ thống, từ đó cùng nhau quyết định tương lai của tổ chức.',
    pianzhnglabel4: 'Sáng lập Quỹ CITADEL AI nhằm mục đích kết nối nhiều người hơn vào',
    pianzhngmsg4: 'quá trình phát triển của CITADEL AI, cùng viết nên hành trình vĩ đại của CITADEL AI',
    mubiao: 'Mục tiêu chính',
    mubiaolabel1: 'Giám sát hệ sinh thái',
    mubiaomsg1: 'Chọn lọc, hỗ trợ và quảng bá các dự án tiềm năng trong hệ sinh thái CITADEL AI.',
    mubiaolabel2: 'Xác minh công nghệ',
    mubiaomsg2: 'Chịu trách nhiệm đảm bảo an ninh mạng thông qua sản xuất khối và xác minh giao dịch.',
    mubiaolabel3: 'Phối hợp liên chuỗi',
    mubiaomsg3: 'Thiết lập quan hệ hợp tác với các thực thể blockchain khác, đảm bảo CITADEL AI duy trì khả năng tương tác và cập nhật theo tiêu chuẩn ngành mới nhất.',
    mubiaolabel4: 'Xây dựng cộng đồng',
    mubiaomsg4: 'Dẫn dắt phát triển cộng đồng, xây dựng sự nhất trí, tạo ra một không gian tương tác tích cực, đồng thời thúc đẩy sự phát triển mạnh mẽ của hệ sinh thái CITADEL AI.',
    mubiaolabel5: 'Vận hành và quản lý DAO',
    mubiaomsg5: 'Giám sát việc đề xuất, thảo luận và bỏ phiếu trong cộng đồng, đảm bảo rằng các đề xuất này tuân thủ nhiệm vụ và giá trị của CITADEL AI.',
    mubiaolabel6: 'Mobilize vốn',
    mubiaomsg6: 'Giám sát công việc tài trợ để đảm bảo sự phát triển và ổn định tài chính của hệ sinh thái CITADEL AI.',
    time1: 'Quý 4 năm 2023',
    time2: 'Quý 1 năm 2024',
    time3: 'Quý 2 năm 2024',
    time4: 'Quý 3 năm 2024',
    time5: 'Quý 4 năm 2024',
    time6: "2025",
    time7: "2026",
    timemsg1: 'Tuyển dụng tích cực, mục tiêu thu hút 100.000 người dùng trên toàn cầu, bao gồm 20 quốc gia',
    timemsg2: 'Thúc đẩy nghiên cứu và triển khai Web3, đồng bộ hóa hệ sinh thái CITADEL AI với các phát triển mới nhất của Web3',
    timemsg3: 'Hỗ trợ chương trình tài trợ Aclevate, quảng bá các nhóm phát triển trò chơi đã được CITADEL AI chấp thuận, tăng số lượng fan và sự nổi tiếng trên thị trường, làm cho hệ sinh thái CITADEL AI có sức ảnh hưởng lớn hơn trong lĩnh vực tài chính trò chơi',
    timemsg4: 'Tổ chức hội nghị trực tuyến và trực tiếp, giao dịch, triển lãm và các hoạt động cộng đồng khác, thu hút thêm doanh nghiệp và người dùng tham gia CITADEL AI',
    timemsg5: 'Củng cố mối quan hệ với đối tác toàn cầu, cùng nhau thúc đẩy phát triển hệ sinh thái CITADEL AI',
    timemsg6: 'Xây dựng Liên minh GameFi, biến CITADEL AI trở thành nhà lãnh đạo về trò chơi kiếm tiền và sáng tạo GameFi khác',
    timemsg7: 'Thành công trong việc thu hút 500.000 người dùng trên toàn cầu, bao gồm ít nhất 50 quốc gia, hỗ trợ CITADEL AI tăng thêm ít nhất 30 dự án mới ở các lĩnh vực khác nhau trong hệ sinh thái của mình và tiếp tục mở rộng và củng cố tính năng và đa dạng của hệ sinh thái CITADEL AI',
    timemsg8: 'Hỗ trợ xây dựng Trung tâm khởi nghiệp CITADEL AI, ủng hộ và thúc đẩy phát triển các dự án sáng tạo trên chuỗi công khai CITADEL AI',
    timemsg9: 'Thúc đẩy hệ sinh thái SocialFi, biến CITADEL AI trở thành người tiên phong trong tài chính xã hội và các sáng kiến ​​liên quan',
    timemsg10: 'Thành lập Học viện CITADEL AI, cung cấp nguồn tài liệu giáo dục và đào tạo, nâng cao kiến thức và kỹ năng về blockchain cho các thành viên cộng đồng',
    timemsg11: 'Khởi xướng nhiều chương trình thưởng cộng đồng, khuyến khích nhiều người tham gia vào việc xây dựng hệ sinh thái CITADEL AI',
    timemsg12: 'Thành công trong việc thu hút 1.000.000 người dùng trên toàn cầu, bao gồm 100 quốc gia',
    timemsg13: 'Biến CITADEL AI thành hệ sinh thái blockchain mạnh mẽ và đa dạng nhất trên toàn cầu',
    genzongtitle: "Robot theo dõi khai thác thanh khoản DeFi",
    genzonglabel: "Công cụ cuối cùng cho việc giám sát đầu tư và tối đa hóa lợi nhuận.",
    genzongmsg1: "Robot của chúng tôi được thiết kế tỉ mỉ, có thể theo dõi và phân tích lợi nhuận được tạo ra từ các công cụ và chiến lược đầu tư khác nhau trong hệ sinh thái tài chính phi tập trung (DeFi).",
    genzongmsg2: "Nhà đầu tư có thể nhận được cái nhìn độc đáo về hiệu suất đầu tư và khả năng sinh lời từ robot tiên tiến nhất của chúng tôi. Dễ dàng truy cập các chỉ số lợi nhuận mới nhất, đánh giá tính hiệu quả của chiến lược, đưa ra quyết định thông minh, tối ưu hóa lợi nhuận đầu tư.",
    genzongmsg3: "Dù là thế chấp, khai thác thanh khoản hay tham gia hồ bơi thanh khoản, robot theo dõi khai thác thanh khoản DeFi của chúng tôi đều sẽ chú ý chặt chẽ đến tình hình đầu tư của bạn, cung cấp dữ liệu thời gian thực và phân tích toàn diện.",
    genzongmsg4: "Đừng bỏ lỡ cơ hội tối đa hóa lợi nhuận",
    trade: "Robot giao dịch DeFi",
    tradelable: "Giới thiệu robot giao dịch tự động tiên tiến nhất trên toàn cầu",
    chuangjian: "Tạo",
    jiaoyisuanfa: "Thuật toán giao dịch",
    shiyong: "Sử dụng",
    zhibiao: "Các chỉ số công nghệ được tùy chỉnh của chúng tôi",
    jiedu: "Giải nghĩa",
    xinhao: "Tín hiệu dễ dàng",
    trademsg1: "Chúng tôi đã tích hợp robot giao dịch được phát triển độc lập vào các thiết bị cuối có đầy đủ tính năng, hoạt động trên một số sàn giao dịch phi tập trung chính, cung cấp cho khách hàng một quá trình thực thi hiệu quả và chính xác",
    trademsg2: "Chúng tôi sẽ tích hợp hệ thống của mình với chuỗi ERC trong lần nâng cấp tiếp theo để tăng khối lượng giao dịch, đồng thời tăng doanh thu cho khách hàng và cung cấp một mạng lưới ổn định giúp khách hàng nắm bắt cơ hội trên thị trường tiền điện tử đang chuyển động.",
    kefu: "Hỗ trợ Telegram"
  },
  HomeFramework: {
    bannerTitle: 'Mở ra một thế giới định lượng phi thường',
    bannerMsg1: 'Xây dựng nền tảng giàu có trí tuệ trong lĩnh vực AI thế hệ mới',
    bannerMsg2: 'Edison AI, sức mạnh bí ẩn đến từ sâu trong AI, cho phép những cá nhân bình thường cũng có thể tạo ra ánh sáng rực rỡ.',
    bannerMsg3: 'Công bằng/Tự do/Niềm tin/Tương lai',
    aboutTitle1: 'Nhiều cấp độ',
    aboutTitle2: 'Nhiều góc',
    aboutTitle3: 'Nhiều dữ liệu',
    aboutMsg1: 'Bao gồm mô hình ở ba cấp độ phân bổ tài sản lớn, lựa chọn ngành nghề, lựa chọn tài sản cụ thể.',
    aboutMsg2: 'Ý tưởng cốt lõi của đầu tư định lượng bao gồm chu kỳ vĩ mô, cấu trúc thị trường, định giá, tăng trưởng, chất lượng lợi nhuận, dự báo lợi nhuận của nhà phân tích, tâm lý thị trường và nhiều quan điểm khác',
    aboutMsg3: 'Xử lý một lượng lớn dữ liệu',
    robotTitle: 'Robot định lượng Edison AI',
    robotMsg: 'Đây là một trợ lý tài chính thông minh thế hệ mới, kết hợp với phân tích dữ liệu mô hình lớn AI tiên tiến, cung cấp phân tích xu hướng thị trường chính xác để giúp bạn đưa ra quyết định đầu tư sáng suốt. Nó sẽ điều chỉnh chiến lược đầu tư được cá nhân hóa cho bạn dựa trên nhu cầu và sở thích rủi ro của bạn, mang lại lợi tức đầu tư cao hơn.',
    platformTitle: 'Tầm nhìn nền tảng',
    platformMsg: 'Sử dụng trí tuệ nhân tạo và công nghệ blockchain trong bộ nhớ để tạo ra một nền tảng tài chính toàn diện, thiết lập một môi trường đầu tư an toàn, minh bạch, công bằng và hiệu quả cho tất cả mọi người.',
    footerMsg: 'Edison AI Wealth Center là một nền tảng quản lý tài sản dựa trên trí tuệ nhân tạo AI, dữ liệu lớn và công nghệ blockchain bộ nhớ, tập trung vào các lĩnh vực đổi mới AI mới, nguồn mở và phi tập trung, với Edison AI FDT Limited UK Foundation làm nền tảng, được hỗ trợ bởi cơ sở dữ liệu mô hình lớn AI, siêu toán AI và dữ liệu khổng lồ TPS, tạo thành một bộ mã chạy thông minh hoàn hảo, cho phép AI Quantitative thực sự công bằng, an toàn, hiệu quả và nhanh chóng.',
    menu2: 'Ủy thác của tôi',
    menu3: 'Hóa đơn của tôi',
    menu4: 'Lợi nhuận của tôi',
    menu5: 'Tài sản của tôi',
    menu6: "Dịch vụ khách hàng của tôi",
    menu7: "Chiến lược định lượng",
  },
  HomeService: {
    title: 'Dịch vụ chúng tôi cung cấp',
    msg: 'Hệ thống Edison có một cơ sở dữ liệu mô hình AI mạnh mẽ, với sự hỗ trợ kỹ thuật và hỗ trợ dữ liệu đầy đủ từ Two Sigma, được hỗ trợ bởi một lượng lớn dữ liệu và sức mạnh tính toán. Với hơn 10.000 nguồn dữ liệu, 95.000 CPU trên 1.000 TB, 22 triệu GB dữ liệu tăng ngay lập tức, tốc độ tính toán có thể đạt 1 X 10 ^ 14 mỗi giây và dung lượng cơ sở dữ liệu lên tới 1.695 TB, nó không gặp trở ngại gì khi chạy các ý tưởng đầu tư phức tạp nhất.',
    serviceTitle1: 'Dịch vụ định lượng',
    serviceTitle2: 'Giải quyết điểm đau',
    serviceTitle3: 'Hệ thống giao dịch thông minh',
    serviceTitle4: 'Ví dụ thông minh',
    serviceTitle5: 'Lợi thế giao dịch',
    serviceTitle6: 'Công nghệ giao dịch',
    list1Msg1: 'Nền tảng cơ bản AI cấp doanh nghiệp, thuật toán giao dịch định lượng hàng đầu, học máy tự động, tối ưu hóa siêu tham số, hiệu quả xử lý định lượng AI tăng nhanh',
    list1Msg2: 'PB định lượng dữ liệu tài chính tiêu chuẩn độc quyền+dữ liệu lớn đầu tư mới, hỗ trợ tất cả các loại dữ liệu tùy chỉnh, đáp ứng nhu cầu đầu tư khác nhau',
    list1Msg3: '2000+Thư viện các yếu tố cơ bản, AI hỗ trợ khai thác nhiều yếu tố phái sinh hơn, đi trước một bước và tiếp cận nhiều cơ hội thị trường hơn',
    list1Msg4: 'Expression Engine tạo ra một thư viện đa dạng các yếu tố hỗ trợ phân tích kỹ lưỡng các yếu tố và phát hiện các yếu tố hiệu quả nhanh hơn',
    list1Msg5: 'Môi trường phát triển trực quan mô-đun tích hợp liền mạch với phiên bản mã Python để tăng hiệu quả phát triển chiến lược',
    list1Msg6: 'Tìm kiếm siêu thông số, tối ưu hóa kết hợp, đào tạo cuộn, phân tích phân bổ và nhiều hơn nữa để tùy chỉnh thiết bị cho người chơi cao cấp',
    list2Msg1: 'Kể từ năm 2021, EDISON AI chỉ hợp tác sâu với các sàn giao dịch chính thống như Binance, EuroEase và Huobi, tránh được sự chạy trốn của các sàn giao dịch.',
    list2Msg2: 'Theo mặc định, khách hàng được khuyến cáo chỉ giao dịch các loại tiền tệ cũ chính thống, tránh các altcoin và pop-coin và tránh các sự cố tương tự như LUNA Thunder.',
    list2Msg3: 'Nó không bị ảnh hưởng bởi bất kỳ thông tin truyền thông nào về việc cùng cắt tỏi tây và thực hiện các chiến lược nghiêm ngặt và toàn diện để tránh tác động tiêu cực của cảm xúc của người dùng.',
    list2Msg4: 'Robot này được triển khai trong các dịch vụ đám mây, không chiếm máy tính và mạng cục bộ của bạn và tự động chạy chiến lược 24/24 để giải quyết các vấn đề về năng lượng của khách hàng.',
    list2Msg5: 'Tính toán và tốc độ giao dịch thông minh tự động 24 giờ, nắm bắt giá thị trường đột ngột, giải quyết vấn đề tốc độ nhân tạo của thị trường lớn không theo kịp.',
    list2Msg6: 'Mô hình toán học giao dịch tích hợp EDISON được tối ưu hóa liên tục trong ba năm, tính toán chế độ thông minh Margin Call thông qua lịch sử thống kê dữ liệu lớn kết hợp với công thức tính toán',
    list3Msg1: 'Bằng cách theo dõi dữ liệu dòng K phút, các điểm chốt lời và lợi nhuận được sửa đổi một cách thông minh để tối đa hóa lợi nhuận và giảm chi phí mua hàng.',
    list3Msg2: 'Giải quyết vấn đề thác nước và pin của lệnh treo thủ công bằng cách đặt đường phút lên và xuống trên một giá trị nhất định, tức là điều chỉnh khoảng thời gian hoặc dừng lại.',
    list3Msg3: 'Chiến lược tùy biến bao gồm Martingale, Flat Push Series, Fibonacci và nhiều hơn nữa để tối đa hóa rủi ro cho khách hàng của bạn từ cờ bạc stud',
    list3Msg4: 'Mô hình trí tuệ thích hợp cho Tiểu Bạch khởi động, mô hình chuyên nghiệp+mô hình danh ngạch do bậc thầy lượng hóa phụ trợ, thích hợp cho tất cả mọi người',
    list3Msg5: 'Thị trường gấu có thể làm trống, thị trường bò có thể mở nhiều hơn, thị trường chấn động cũng có thể mở nhiều hơn, thị trường đơn phương có thể liên kết nhiều hơn, nhằm vào tình huống khác nhau của mỗi bên.',
    list3Msg6: 'Tính toán chính xác số tiền giao dịch cho mỗi lệnh cho các loạt giao dịch khác nhau, tránh tối đa các vấn đề rườm rà về tính toán thủ công',
    list4Msg1: 'Mô hình toán học thông minh, mô hình tính toán tích hợp EDISON, theo giá hiện tại và dữ liệu lịch sử, có một khoảng thời gian biến động, tính toán thông minh, điều chỉnh cuộc gọi ký quỹ tiếp theo trong thời gian thực',
    list4Msg2: 'Đường K theo dõi thời gian thực thông minh, áp dụng để theo dõi lợi nhuận theo dõi chức năng mở vị trí, điều chỉnh lợi nhuận và điểm thoái lui ký quỹ trong thời gian thực, khuếch đại lợi nhuận và giảm chi phí',
    list4Msg3: 'Giám sát độ sâu giao dịch một cách thông minh, theo dõi độ sâu giao dịch ở mức tối đa của sàn giao dịch, theo đuổi giá mua và bán tốt nhất',
    list4Msg4: 'Thống kê thông minh Dữ liệu lịch sử, Thống kê thông minh Dữ liệu lịch sử tiền tệ và biên độ biến động, hỗ trợ hoạt động ổn định của chế độ thông minh',
    list4Msg5: 'Điều chỉnh tốc độ giao dịch thông minh, điều chỉnh tốc độ giao dịch thông minh theo biến động thị trường, nhanh chóng nắm bắt giá tốt nhất trong khi tối ưu hóa hoạt động ổn định của máy chủ',
    list5Msg1: 'Tốc độ giao dịch',
    list5Msg2: 'mili giây, nhanh, ổn định',
    list5Msg3: 'Thời gian năng lượng',
    list5Msg4: 'Giao dịch 24 giờ một ngày mà không cần ngủ',
    list5Msg5: 'Dữ liệu thông minh',
    list5Msg6: 'Tính toán vị trí và tính toán dữ liệu giao dịch nhanh chóng và chính xác',
    list5Msg7: 'Chế độ giao dịch',
    list5Msg8: 'Một mô hình giao dịch hoàn chỉnh và ổn định',
    list5Msg9: 'Thực hiện chính sách',
    list5Msg10: 'Thực hiện nghiêm túc chiến lược tổng thể',
    list5Msg11: 'Theo dõi thời gian thực',
    list5Msg12: 'Đánh dấu thời gian thực, theo dõi chuyển động thông minh K Line',
    list5Msg13: 'Ảnh hưởng cảm xúc',
    list5Msg14: 'Không bị ảnh hưởng bởi cảm xúc của chủ nhà',
    list5Msg15: 'Ảnh hưởng truyền thông',
    list5Msg16: 'Đừng bị lừa bởi tin giả',
    list5Msg17: 'Lợi nhuận',
    list5Msg18: 'Lợi nhuận ngắn hạn có thể ổn định trong dài hạn',
    list6Msg1: 'Công nghệ giao dịch',
    list6Msg2: 'R&D độc lập, mạnh mẽ và ổn định, công nhận công nghiệp cao',
    list6Msg3: 'Lịch sử chạy',
    list6Msg4: 'EDISON đã tập trung nghiên cứu định lượng trong 8 năm và đã được xuất bản trong 3 năm.',
    list6Msg5: 'Chi phí',
    list6Msg6: 'Thị trường toàn cầu thống nhất, minh bạch và không bị che giấu',
    list6Msg7: 'Chế độ giao dịch',
    list6Msg8: 'Phát triển độc lập mô hình giao dịch ổn định, hai mô hình lớn',
    list6Msg9: 'Tình hình Hội đồng',
    list6Msg10: 'Không',
    list6Msg11: 'Lựa chọn nền tảng',
    list6Msg12: 'Chỉ hỗ trợ Binance Oyi Huobi, khách hàng có thể tự lựa chọn',
    list6Msg13: 'Tình hình an ninh',
    list6Msg14: 'Đảm bảo định lượng 8 năm ổn định của EDISON',
    list6Msg15: 'Tối ưu hóa cập nhật',
    list6Msg16: '4 năm theo kịp với các cập nhật tối ưu hóa thị trường',
    list6Msg17: 'Tổng số người dùng',
    list6Msg18: 'Cơ sở khách hàng ổn định với 70% thị phần+'
  },
  HomeStepper: {
    title: 'Việt',
    title2: 'Kế hoạch phát triển',
    step1Msg1: 'Xây dựng và đào tạo cơ sở dữ liệu mô hình lớn Al;',
    step1Msg2: 'Thành lập nhóm vận hành mã hóa, phụ trách xây dựng đường sắt mã hóa;',
    step2Msg1: 'Đầu tư định lượng AI trí tuệ nhân tạo bắt đầu thực hiện;',
    step2Msg2: 'Tài sản tài chính do AI quản lý có sự tăng trưởng bùng nổ;',
    step2Msg3: 'Nó bắt đầu đầu tư vào các nền tảng giao dịch tiền điện tử chất lượng cao và các dự án DEFI;',
    step3Msg1: 'Two Sigma đầu tư vào Edison Al Foundation',
    step3Msg2: 'Nền tảng Trung tâm Tài sản Trí tuệ Nhân tạo Edison của Quỹ đã được đưa vào hoạt động;',
    step3Msg3: 'Robot tổng hợp AI Edison ra đời;',
    step3Msg4: 'Edison AI DEFI khởi động một phần;',
    step4Msg1: 'Ví công cộng Edison AI được đăng nhập;',
    step4Msg2: 'Edison mã hóa mạng xã hội;',
    step5Msg1: 'Ứng dụng sinh thái chuỗi công cộng Edison Al đang hoạt động;'
  },
  Chongzhi: {
    title: 'Nạp tiền',
    bill: 'Hóa đơn của tôi',
    chakan: 'Xem thêm',
    placeholder: 'Vui lòng nhập số tiền nạp',
    balance: 'Số dư ví',
    queding: 'Xác định',
    guangbo: 'Giao dịch phát sóng thành công',
    message1: 'Vui lòng nhập số tiền nạp',
    message2: 'Không đủ số dư ví',
    message3: 'Đang giao dịch......',
    message4: 'Vui lòng nhập đúng số lượng',
    xiugai: "Sửa đổi",
    bangding: "Bạn không liên kết địa chỉ TRC20, liên kết ngay lập tức",
    message5: "Vui lòng nhập địa chỉ TRC20",
    message6: "Đang sửa đổi......",
    message7: "Vui lòng nhập đúng số lượng",
    message8: "Địa chỉ ví TRON mà bạn đang sử dụng không khớp với địa chỉ bạn đã liên kết, vui lòng sửa đổi trước khi chuyển tiền",
    message9: "* Vui lòng sử dụng địa chỉ ví ràng buộc để chuyển số tiền nạp vào địa chỉ nhận tiền",
    message10: "Sau khi chuyển khoản thành công xin chờ 2 phút, sau khi khu vực xác nhận tự động vào tài khoản!",
    shoubi: "Địa chỉ thanh toán nền tảng",
    bangdingdizhi: "Địa chỉ liên kết",
    copy: "Sao chép địa chỉ",
    shouquan: "Giấy phép",
    shouquanjine: "Số tiền ủy quyền",
    shouquantip: "Không đủ số tiền ủy quyền, vui lòng ủy quyền trước",
    yishouquan: "Được ủy quyền",
    shouquanzhong: "Được ủy quyền...",
    chongzhizhong: "Đang nạp tiền......",
    shouquanchenggogn: "Ủy quyền thành công"
  },
  Bill: {
    title: 'Thanh toán',
    date: 'Thời gian',
    leixing: 'Loại',
    coin: 'Tiền tệ',
    num: 'Số lượng',
    finished: 'Không còn nữa.',
    all: 'Tất cả',
    shouyi: 'Lợi nhuận',
    chongzhi: 'Nạp tiền',
    tixian: 'Rút tiền',
    zhuanzhang: 'Chuyển khoản',
    zhuanhuan: 'Chuyển đổi',
    zengjian: 'Tăng và giảm',
    xiaofei: 'Mua hàng'
  },
  NodeRecord: {
    title: 'Hồ sơ mua hàng',
    date: 'Thời gian',
    node: 'Nút',
    amount: 'Số lượng',
    finished: 'Không còn nữa.'
  },
  Bonus: {
    title: 'Lợi nhuận',
    date: 'Thời gian',
    type: 'Loại',
    jine: 'Số lượng',
    finished: 'Không còn nữa.',
    jinri: 'Lợi nhuận hôm nay',
    leiji: 'Thu nhập tích lũy'
  },
  Team: {
    title: 'Danh sách đẩy thẳng',
    address: 'Địa chỉ',
    jine: 'Số lượng',
    jibie: 'Cấp bậc',
    wu: 'Không',
    yeji: 'Hiệu suất của Team Node'
  },
  User: {
    home: 'Trang chủ',
    chongzhi: 'Trung tâm nạp tiền',
    bonus: 'Lợi nhuận của tôi',
    ai: 'Dữ liệu AI',
    mining: 'Khai thác mỏ',
    notice: 'Thông báo'
  },
  Notice: {
    title: 'Thông báo',
    details: 'Thông báo chi tiết'
  },
  TabBar: {
    home: 'Thị trường',
    ai: 'Ai làm chứng',
    mining: 'Trung tâm tài sản',
    team: 'Đội ngũ',
    user: 'Của tôi'
  },
  Market: {
    dagnqianjiage: 'Giá hiện tại',
    chushi: 'Giá ban đầu',
    mairu: 'Mua vào',
    maichu: 'Bán hàng',
    liudongxing: 'Thanh khoản',
    xinxi: 'Thông tin',
    kongtou: "Giải thưởng Airdrop",
    dangqian: "Tổng cộng",
    daibixiaohui: 'CCAI Token bị phá huỷ',
    xiaohuizongliang: 'Tổng số CCAI bị phá huỷ',
    chubirenshu: 'Số người nắm giữ CCAI',
    faxingzongliang: 'Tổng số CCAI được phát hành',
    jiedian: 'Nút',
    jine: 'Số tiền',
    suanli: 'Sức mạnh tính toán',
    zuori: "Hôm qua",
    jinri: "Hôm nay"
  },
  AiData: {
    zongjiazhi: 'Tổng giá trị khóa (USD)',
    celue: 'Chính sách lưới thuật toán',
    totalMsg: 'Citadel là một trong những quỹ phòng hộ định lượng có ảnh hưởng nhất trên thế giới, sử dụng các mô hình AI và học máy tinh vi để quản lý tài sản và tối ưu hóa giao dịch trên thị trường. Citadel Securities, bộ phận giao dịch điện tử của mình, sử dụng rộng rãi công nghệ AI để nâng cao hiệu quả thực hiện giao dịch. CITADEL AI Robot sẽ dựa trên việc học tập và giám sát dữ liệu lớn hàng trăm giao dịch mỗi ngày, xây dựng và hoàn thiện chiến lược và hình thành các hướng dẫn trong thời gian thực, cung cấp hỗ trợ kỹ thuật và hỗ trợ dữ liệu đầy đủ, đằng sau đó là sự hỗ trợ của một lượng lớn dữ liệu và sức mạnh tính toán. Nó có hơn 10.000 nguồn dữ liệu và 95.000 CPU trên 1.000 TB.',
    botTitle: 'Số lượng lệnh arbitrage',
    jiangli: 'Phần thưởng',
    rilirun: 'Lợi nhuận hàng ngày',
    yuelirun: 'Lợi nhuận hàng tháng',
    jiage: 'Giá cả',
    zjc: "Hồ bơi tài chính",
    ailh: "Định lượng AI",
    aijy: "Giao dịch AI",
    poolStats: "Thống kê Pool",
    arbitrageStats: "Thống kê Arbitrage",
    zongyue: "Tổng số dư",
    baozhengjin: "Ký quỹ (USDT)",
    qianbaoyue: "Số dư ví (USDT)",
    yunxingyingkui: "Lợi nhuận và lỗ (USDT)",
    zongyingkui: "Tổng lợi nhuận và lỗ (USDT)",
    name: "Tiền tệ",
    price: "Giá cả",
    aiweituo: "Ủy thác AI",
    aimingxi: "Chi tiết giao dịch AI",
    dingyue: "Đăng ký",
    jilu: "Ghi chép",
    ziliaoku: "Trung tâm thông tin",
    chengjiaoliang: "Khối lượng giao dịch",
    jiaoyidui: "Cặp giao dịch",
    chengjiaotu: "Biểu đồ Khối lượng",
    paixu: "Sắp xếp",
    bizhong: "Loại tiền",
    price24: "Giá (24 giờ)",
    wending: "Kết nối ổn định",
    zhichi: "Hỗ trợ trực tuyến"
  },
  Mining: {
    mingcheng: 'Mã thông báo',
    fuhao: 'Biểu tượng Token',
    gongyingliang: 'Tổng cung cấp',
    qishi: 'Giá khởi điểm',
    jilu: 'Hồ sơ ủy quyền',
    chakan: 'Xem thêm',
    aibot: 'Robot AI',
    rishouyi: 'Thu nhập hàng ngày',
    weituojine: 'Số lượng ủy thác',
    weituo: 'Trang chủ',
    aggregation: 'Robot tổng hợp',
    placeholder1: 'Vui lòng nhập số tiền',
    queren: 'Xác nhận',
    jijiangkaifang: 'Sắp mở',
    echartsName1: 'Cộng đồng phân phối',
    echartsName2: 'Phân bổ nút',
    echartsName3: 'Phân phối Foundation',
    echartsName4: 'Hồ bơi khai thác Calculus',
    placeholder2: 'Vui lòng nhập',
    loading: 'Trong ủy thác......',
    jiqiren1: "S1 Robot",
    jiqiren2: "S2 Robot",
    jiqiren3: "S3 Robot",
  },
  TeamCount: {
    tuijian: 'Giới thiệu',
    tuijianren: 'Giới thiệu của tôi',
    lianjie: 'Mời kết nối',
    tuandui: 'Đội ngũ',
    teamCount: 'Số lượng đội đăng ký',
    liebiao: 'Danh sách đẩy thẳng',
    usYejiTotal: 'Thành tích cá nhân AI',
    teamYejiTotal: 'Đội ngũ AI thực hiện',
    rtaShouyi: 'Lợi nhuận AI',
    rtbShouyi: 'Thu nhập tổng hợp',
    ktDay: 'Lượng airdrop hàng ngày',
    ktTotal: 'Lợi nhuận IDO',
    jiedianyeji: 'Hiệu suất của Team Node',
    usjiedianyeji: 'Hiệu suất nút cá nhân',
    usxh: 'Tập hợp cá nhân Hủy diệt',
    teamxh: 'Đội tập hợp phá hủy',
    jiedianshouyi: 'Thu nhập nút',
    youxiao: "Số lượng đội có hiệu lực"
  },
  Wallet: {
    total: 'Tổng tài sản',
    bill: 'Hóa đơn của tôi',
    bonus: 'Lợi nhuận của tôi',
    maichu: 'Bán hàng',
    chongzhi: 'Nạp tiền',
    tixian: 'Rút tiền',
    mairu: 'Mua vào',
    shandui: 'Nhấp nháy',
    keyong: 'Có sẵn',
    dongjie: 'Đóng băng',
    zichan: "Tài sản",
    zhuanzhang: 'Chuyển khoản'
  },
  Convert: {
    mairu: 'Mua vào',
    maichu: 'Bán hàng',
    shandui: 'Nhấp nháy',
    yue: 'Số dư',
    placeholder1: 'Vui lòng nhập số lượng',
    edu: 'Hạn mức đăng ký',
    sxf: 'Phí xử lý',
    ranliao: 'Nhiên liệu tương đương CCAI',
    querenmairu: 'Xác nhận mua',
    querenmaichu: 'Xác nhận bán',
    message1: 'Bạn có chắc chắn muốn mua?',
    message2: 'Bạn có chắc chắn muốn bán?',
    loading1: 'Đang tải......',
    message3: 'Bạn có chắc chắn muốn chuyển đổi?',
    loading2: 'Đang pha......'
  },
  Tixian: {
    title: 'Rút tiền',
    bill: 'Hóa đơn của tôi',
    chakan: 'Xem thêm',
    select: 'Vui lòng chọn tiền tệ',
    numLabel: 'Vui lòng nhập số lượng rút tiền',
    placeholder: 'Số lượng rút tiền',
    addressLabel: 'Vui lòng nhập địa chỉ rút tiền',
    placeholder2: 'Địa chỉ rút tiền',
    yue: 'Số dư ví',
    sxf: 'Phí xử lý',
    queding: 'Xác định',
    message1: 'Vui lòng chọn tiền tệ',
    message2: 'Vui lòng nhập số lượng rút tiền',
    message3: 'Anh có chắc là muốn rút tiền mặt không?',
    loading: 'Trong quá trình rút tiền......'
  },
  RobotUserRecord: {
    title: 'Hồ sơ ủy quyền',
    date: 'Thời gian',
    robot: 'Người máy',
    jine: 'Số lượng',
    state: 'Trạng thái',
    finished: 'Không còn nữa.',
    shouyi: 'Trong thu nhập',
    shuhui: 'Giải cứu',
    yijieshu: 'Đã kết thúc',
    yishuhui: 'Đã chuộc lại',
    empty: 'Không có hồ sơ.',
    message: 'Anh có chắc là muốn chuộc lại không?',
    loading: 'Trong quá trình chuộc lại......',
    ranshao: "nhiên liệu",
    suanli: "Sức mạn",
    jiedianyeji: "Hiệu suất nút",
    aiyeji: "Hiệu suất AI"
  },
  NodePower: {
    title: 'Sức mạnh của Node',
    date: 'Thời gian thưởng',
    jine: 'Số tiền thưởng',
    finished: 'Không còn nữa.'
  },
  Record: {
    cz: 'Hồ sơ nạp tiền',
    tx: "Hồ sơ rút tiền",
    shijian: "Thời gian",
    jine: "Số lượng",
    hash: "Giao dịch Hash",
    zz: "Hồ sơ chuyển tiền",
    suserid: "Người nhận tiền"
  },
  Zhuanzhang: {
    title: "Chuyển khoản",
    bill: "Hồ sơ chuyển tiền",
    chakan: "Xem thêm",
    select: "Vui lòng chọn tiền tệ",
    numLabel: "Vui lòng nhập số lượng chuyển khoản",
    placeholder: "Số lượng chuyển khoản",
    addressLabel: "Vui lòng nhập địa chỉ người nhận",
    placeholder2: "Địa chỉ người nhận",
    yue: "Số dư ví",
    sxf: "Phí xử lý",
    queding: "Xác định",
    message1: "Vui lòng chọn tiền tệ",
    message2: "Vui lòng nhập số lượng chuyển khoản",
    message3: "Anh có chắc là muốn chuyển tiền không?",
    loading: "Đang chuyển tiền......"
  },
  AiTradeDetail: {
    title: "Chi tiết giao dịch",
    pingcang: "Đóng vị trí",
    jiage: "Giá (USDT)",
    num: "Số lượng (USDT)",
    shouyi: "Lợi nhuận",
    yongxu: "Vĩnh viễn"
  },
  Service: {
    shuruwenti: "Vui lòng nhập câu hỏi của bạn",
    bunengweikong: "Nội dung tin nhắn không được để trống",
    shangchuanshibai: "Tải ảnh lên không thành công",
    message: "Vui lòng tải lên hình ảnh trong vòng 5M",
    message2: "Vui lòng upload ảnh JPG"
  },
  Subscribe: {
    title: "Đăng ký",
    zhineng: "Giao dịch trí tuệ nhân tạo",
    zongdingyue: "Tổng số đăng ký",
    zonghuoyue: "Tổng số hoạt động",
    zhungtai: "Trạng thái",
    shuchu: "Đầu ra tối đa",
    zhanghu: "Tài khoản",
    beidongshouru: "Thu nhập Passice",
    zhudongshouru: "Thu nhập tích cực",
    zongshouru: "Tổng thu nhập",
    mima: "Mật khẩu"
  },
  aiLogin: {
    title: "Chào mừng trở lại!",
    label: "Vui lòng nhập tài khoản của bạn để đăng nhập",
    account: "Tài khoản",
    password: "Mật khẩu",
    check: "Nhớ tôi",
    submit: "Đăng nhập",
    placeholder1: "Nhập tài khoản của bạn",
    placeholder2: "Nhập mật khẩu của bạn"
  },
  Trading: {
    tubiao: "Biểu đồ",
    huobi: "Tiền tệ",
    jiage: "Giá",
    buy: "Mua"
  },
  NavBar: {
    logout: "Đăng xuất",
    shouye: "Trang chủ",
    positions: "Vị thế Mở",
    closedPositions: "Vị thế Đóng",
    aiConfig: "Cấu hình AI"
  },
  Pledge: {
    zhiya: "Thế chấp",
    zhiyajilu: "Lịch sử thế chấp",
    xuanzezhouqi: "Chọn chu kỳ",
    qingxuanzezhouqi: "Vui lòng chọn chu kỳ",
    zhiyashuliang: "Số lượng thế chấp",
    qingshurubeishu: "Vui lòng nhập số là 100 hoặc bội số của 100",
    zhifufangshi: "Phương thức thanh toán",
    querenzhiya: "Xác nhận thế chấp",
    qingshurushuliang: "Vui lòng nhập số lượng",
    zhiyazhong: "Đang thế chấp...",
    shijian: "Thời gian",
    zhouqi: "Chu kỳ",
    jine: "Số tiền",
    zhuangtai: "Trạng thái",
    chezi: "Thu hồi",
    shouyizhong: "Đang nhận lãi",
    yichezi: "Đã thu hồi",
    querenchezi: "Bạn có chắc muốn thu hồi không?",
    chezizhong: "Đang thu hồi..."
  }
}
<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>
<script>
import { ref } from "vue";
export default {
  name: "LayoutDefault",
  components: {},
  setup() {
    return {
      leftDrawerOpen: ref(false),
    };
  },
  created() {
    this.$utils.setloc('loginCount', 0)
    localStorage.setItem('markState', 0)
    //在页面加载时读取sessionStorage里的状态信息
    if (this.$utils.getloc("store")) {
      this.$store.replaceState(
        Object.assign({},
          this.$store.state,
          JSON.parse(this.$utils.getloc("store"))
        )
      );
    }
    if (this.$utils.GetQueryString('action') == 'login') {
      var t = this.$utils.GetQueryString('t');
      if (t != null) {
        this.$utils.setloc("token", t);
        this.$utils.setloc("id", this.$utils.GetQueryString('id'));
        this.$utils.setloc("userid", this.$utils.GetQueryString('userid'));
        this.$utils.setloc("username", this.$utils.GetQueryString('username'));
        this.$utils.setloc("recode", this.$utils.GetQueryString('recode'));
        this.$utils.setloc(
          "tx",
          this.$config.send_url + "Upload/" + this.$utils.GetQueryString('tx')
        );
        this.show = false;
        this.$store.commit("setLoginState", true);

        setTimeout(function () {
          //重定向,清除掉地址栏的参数,防止退出时反复刷新
          window.location.href = '?';
        }, 1000);

      }
    }

    try {
      window.addEventListener("beforeunload", () => {
        this.$utils.setloc("store", JSON.stringify(this.$store.state));
      });
    } catch {
      console.log('beforeunload error');
      try {
        window.addEventListener("pagehide", () => {
          this.$utils.setloc("store", JSON.stringify(this.$store.state));
        });
      } catch {
        console.log('pagehide error');
        try {
          window.addEventListener("unload", () => {
            this.$utils.setloc("store", JSON.stringify(this.$store.state));
          });
        } catch {
          console.log('unload error');
        }
      }
    }
  },
  mounted() {
    //解决安卓返回键退出APP问题
    let _this = this;
    document.addEventListener('plusready', function (a) {
      var first = '';
      // eslint-disable-next-line no-undef
      plus.key.addEventListener('backbutton', function () {
        //获取地址栏目中的url
        var urls = location.hash.split('/')[1]
        //判断是一级页面的时候点击两次退出app
        if (urls === 'ShopIndex' || urls === '') {
          if (new Date().getTime() - first < 3000) {
            // eslint-disable-next-line no-undef
            plus.runtime.quit(); //表示退出app
          } else {
            // eslint-disable-next-line no-undef
            plus.nativeUI.toast('再按一次退出应用')
            first = new Date().getTime();
          }
        } else {
          _this.$router.go(-1);
        }
      }, false)
    });
  }
};
</script>

<style>
body * {
  box-sizing: border-box;
  /* flex-shrink: 0; */
}

body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma,
    Arial, PingFang SC-Light, Microsoft YaHei;
  background-color: #f4f4f9;
}

.van-popover__action-text {
	text-align: center;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Conthrax", sans-serif;
}

.main-color {
  color: #2C8B9F;
}
</style>
